import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid, IconButton } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

import MedicalStatus from "../MedicalStatus";

const ConsentDetailDownload = props => {
  const {
    handleSelectedConsentDownload,
    label,
    title,
    id,
    status,
    medicalActCode,
  } = props;

  return (
    <Grid
      key={id}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography sx={{ my: 1 }} variant="subtitle2">
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        xs={7}
        sx={{
          my: "12px",
        }}
      >
        <Typography variant="body2">{title}</Typography>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MedicalStatus status={status} />
      </Grid>
      {status !== "REQUESTED" && (
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <IconButton
            href="#"
            onClick={() => handleSelectedConsentDownload(id, medicalActCode)}
          >
            <CobaltIcon
              name="download"
              sx={{
                color: "cobalt.ultramarine",
                fontSize: 18,
              }}
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
ConsentDetailDownload.defaultProps = {
  label: "",
};

ConsentDetailDownload.propTypes = {
  id: PropTypes.string.isRequired,
  medicalActCode: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  label: PropTypes.string,
  handleSelectedConsentDownload: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConsentDetailDownload;
