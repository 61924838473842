import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const StatusMenu = props => {
  const { currentChoices, setCurrentChoices } = props;

  const handleStatusChange = event =>
    setCurrentChoices({ ...currentChoices, status: event.target.value });

  return (
    <FormControl component="div" fullWidth>
      <FormLabel>
        <Typography variant="subtitle2">Statut des consentements</Typography>
      </FormLabel>
      <RadioGroup
        row
        aria-label="consents-status"
        name="consents-status-radio-group"
        sx={{
          width: "100%",
          margin: "25px 0px 5px 12px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormControlLabel
          value=""
          control={<Radio checked={currentChoices.status === ""} />}
          onChange={handleStatusChange}
          label="Tous"
        />
        <FormControlLabel
          value="COMPLETED"
          control={<Radio checked={currentChoices.status === "COMPLETED"} />}
          onChange={handleStatusChange}
          label="Signé"
        />
        <FormControlLabel
          value="REQUESTED"
          control={<Radio checked={currentChoices.status === "REQUESTED"} />}
          onChange={handleStatusChange}
          label="En attente"
        />
      </RadioGroup>
    </FormControl>
  );
};

StatusMenu.propTypes = {
  setCurrentChoices: PropTypes.func.isRequired,
  currentChoices: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    interventions: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  }).isRequired,
};

export default StatusMenu;
