import hubService from "./HubService";

const ClinicService = {
  getClinicDetail: healthFacilityId => {
    const url = `HEALTH_FACILITY?k1=${healthFacilityId}`;

    return hubService.request("GET", url, null, false, true);
  },
  getClinicImages: healthFacilityId => {
    const url = `HEALTH_FACILITY_PICTURE?k1=${healthFacilityId}`;

    return hubService.request("GET", url, null, false, true);
  },
  getClinicGDPRInformationNote: healthFacilityId => {
    const url = `INFORMATION_NOTE?k1=${healthFacilityId}&k2=GDPR`;

    return hubService.request("GET", url, null, false, true);
  },
};

export default ClinicService;
