import React, { useState, useEffect, useRef, useCallback } from "react";
import { useKeycloak } from "context/KeycloakProvider";
import format from "date-fns/format";

import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableContainer,
  TablePagination,
  TableSortLabel,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import FilterButton from "@mui/material/ToggleButton";

import InputSearchIcon from "../../icons/inputSearch";
import useMedicalActCode from "../../hooks/useMedicalActCode";

import MedicalStatus from "../../components/medicalConsents/MedicalStatus";
import FetchingRows from "../../components/medicalConsents/FetchingRows";
import EmptySearch from "../../components/medicalConsents/EmptySearch";
import ConsentDetailPanel from "../../components/medicalConsents/consentsDetail/ConsentDetailPanel";
import FilterModal from "../../components/medicalConsents/interactions/FilterModal";

import ClinicUtils from "../../utils/ClinicUtils";
import InterventionUtils from "../../utils/InterventionUtils";
import TableUtils from "../../utils/TableUtils";
import SnackbarUtil from "../../utils/SnackbarUtil";

import ConsentService from "../../services/ConsentService";

const { getComparator, stableSort } = TableUtils;

const MedicalConsentsSearch = () => {
  const { keycloak, initialized } = useKeycloak();
  const [healthFacilityId, setHealthFacilityId] = useState(null);

  const { medicalActCodes } = useMedicalActCode(healthFacilityId);

  const [isFetching, setIsFetching] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [medicalConsents, setMedicalConsents] = useState([]);
  const [filteredConsents, setFilteredConsents] = useState([]);
  const [selectedIntervention, setSelectedIntervention] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("patientLocalIpp");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isRelaunchSent, setIsRelaunchSent] = useState(null);
  const [currentHeight, setCurrentHeight] = useState(null);

  const [filterCriterias, setFilterCriterias] = useState({
    date: null,
    interventions: [],
    status: "",
  });

  const setSnackbar = SnackbarUtil.useSnackbar()[1];
  // Récupère le HealthFacilityId
  const ref = useRef(null);

  // Harmonise la hauteur entre le tableau de consentements et le panneau latéral
  useEffect(
    () => setCurrentHeight(ref.current.getBoundingClientRect().height),
    [medicalConsents, rowsPerPage, page],
  );

  // Affichage de la bordure de la séparation
  const isPageFull = useCallback(() => {
    if (medicalConsents.length === 0) {
      return false;
    }
    if ((rowsPerPage > medicalConsents.length && page === 0) || isFetching) {
      return true;
    }
    return (page + 1) * rowsPerPage < medicalConsents.length;
  }, [page, rowsPerPage, medicalConsents, isFetching]);
  // Récupère le HealthFacilityId
  useEffect(() => {
    if (initialized) {
      if (!healthFacilityId) {
        keycloak
          .loadUserInfo()
          .then(informations => {
            const { institution } = informations;
            const hfId = ClinicUtils.getCodeEtablissement(institution);

            setHealthFacilityId(hfId);
          })
          .catch(() => setHealthFacilityId(null));
      }
    }
  }, [healthFacilityId, keycloak, initialized]);

  // Fait les recherches de consentements médicaux
  useEffect(() => {
    const getInterventionDay = date => format(date, "yyyy-MM-dd");

    const getMedicalConsents = () => {
      const { date, interventions, status } = filterCriterias;

      setMedicalConsents([]);
      setPage(0);
      setIsFetching(true);

      if (healthFacilityId !== null && interventions.length !== 0) {
        ConsentService.getMedicalConsents(
          healthFacilityId,
          interventions,
          getInterventionDay(date),
          status,
        )
          .then(response => {
            const { data } = response;
            setIsFetching(false);

            // Filtrage des datas par interventions
            const medicalConsentsByInterv = data.reduce((acc, val) => {
              const intervention = acc.filter(
                i => i.interventionId === val.interventionId,
              );

              if (intervention.length === 0) {
                acc.push({
                  interventionId: val.interventionId,
                  healthFacilityId: val.healthFacilityId,
                  medicalActCode: val.medicalActCode,
                  patientId: val.patientId,
                  interventionDate: val.interventionDate,
                  intentId: val.intentId,
                  patientBirthDate: val.patientBirthDate,
                  patientLastName: val.patientLastName,
                  patientFirstName: val.patientFirstName,
                  patientLocalIpp: val.patientLocalIpp,
                  statusHistory: val.statusHistory,
                  consents: [val],
                });
              } else {
                intervention[0].consents.push(val);
              }
              return acc;
            }, []);

            setMedicalConsents(medicalConsentsByInterv);
            setFilteredConsents(medicalConsentsByInterv);
            setIsFiltered(true);
          })
          //! Check gestion erreurs
          .catch(() => {
            setMedicalConsents([]);
            setFilteredConsents([]);
            setIsFiltered(false);
            setIsFetching(false);
          });
      } else {
        setIsFiltered(false);
        setIsFetching(false);
      }
    };

    if (
      filterCriterias !== undefined ||
      filterCriterias.interventions.length !== 0
    ) {
      getMedicalConsents();
    }
  }, [filterCriterias, healthFacilityId]);

  useEffect(() => {
    setIsRelaunchSent(null);
  }, [selectedIntervention]);

  const openAlert = (message, type, duration) => {
    setSnackbar({ isOpen: true, message, type, autoHideDuration: duration });
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnHover = consent => {
    setSelectedIntervention(consent);
  };

  const handleSort = property => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchConsents = event => {
    const searchValue = event.target.value;
    const consents = filteredConsents.filter(
      consent =>
        `${consent.patientLastName} ${consent.patientFirstName}`
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        `${consent.patientFirstName} ${consent.patientLastName}`
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        (consent?.patientLocalIpp &&
          consent.patientLocalIpp
            .toLowerCase()
            .includes(searchValue.toLowerCase())),
    );
    setMedicalConsents(consents);
  };

  const resetAllFilters = () => {
    setFilterCriterias({
      date: null,
      interventions: [],
      status: "",
    });
  };

  const headCells = [
    { id: "patientLocalIpp", label: "IPP" },
    { id: "patientLastName", label: "Nom prénom" },
    { id: "patientBirthDate", label: "Date de naissance" },
    { id: "interventionDate", label: "Date d'intervention" },
    { id: "medicalActCode", label: "Nom de l'intervention" },
    { id: "status", label: "Statut" },
  ];

  const sendEmailRelaunch = patientId => {
    ConsentService.sendEmailRelaunch(patientId)
      .then(response => {
        setIsRelaunchSent(response.status === 204);
        openAlert("Un email a été envoyé", "success", 3000);
      })
      .catch(err => {
        const { response } = err;
        const { status, statusText } = response;

        if (status === 404 && statusText === "EMAIL_NOT_FOUND") {
          openAlert(
            "L'email n'a pas été envoyé car l'email ou le numéro de téléphone est inconnu",
            "error",
            null,
          );
        } else if (status >= 400) {
          openAlert("Une erreur est survenue", "error", null);
        }
      });
  };

  return (
    <Box>
      <FilterModal
        isModalOpen={isModalOpen}
        filterCriterias={filterCriterias}
        setIsModalOpen={setIsModalOpen}
        medicalActCode={medicalActCodes}
        setFilterCriterias={setFilterCriterias}
        resetAllFilters={resetAllFilters}
      />

      <Grid container spacing={3}>
        <Grid item sm={8} md={9} lg={9.5}>
          <Stack mb={3} direction="row" justifyContent="space-between">
            <Typography variant="h2">
              Patients et consentements médicaux
            </Typography>
          </Stack>
          <Paper
            sx={{
              minHeight: "calc(100vh - 236px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              pt: 2,
            }}
            ref={ref}
          >
            <TableContainer>
              <Box ml={3}>
                <Grid container>
                  {isFiltered && medicalConsents.length >= 0 && (
                    <Grid item xs={12} md={4} mr={3}>
                      <TextField
                        variant="outlined"
                        placeholder="Rechercher par nom, IPP"
                        fullWidth
                        onChange={handleSearchConsents}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <InputSearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <FilterButton
                    value="filtrer"
                    onClick={() => setIsModalOpen(!isModalOpen)}
                    selected={isModalOpen || isFiltered}
                    disabled={medicalActCodes.length === 0}
                  >
                    Filtrer
                  </FilterButton>
                </Grid>
              </Box>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headCells.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        sx={{ backgroundColor: "white" }}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={() => {
                            handleSort(headCell.id);
                          }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!isFetching && medicalConsents.length === 0 ? (
                    <EmptySearch
                      setSelectedIntervention={setSelectedIntervention}
                      isFiltered={isFiltered}
                      setIsModalOpen={setIsModalOpen}
                      isModalOpen={isModalOpen}
                      disabledSearch={medicalActCodes.length === 0}
                      height={rowsPerPage <= 10 ? 64 * rowsPerPage : 640}
                    />
                  ) : (
                    stableSort(medicalConsents, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map(intervention => (
                        <TableRow
                          key={intervention.interventionId}
                          hover
                          onClick={() => handleOnHover(intervention)}
                          selected={
                            intervention.interventionId ===
                            selectedIntervention?.interventionId
                          }
                        >
                          <TableCell sx={{ textAlign: "left" }}>
                            {intervention.patientLocalIpp}
                          </TableCell>
                          <TableCell>
                            {`${intervention.patientLastName} ${intervention.patientFirstName}`}
                          </TableCell>
                          <TableCell>
                            {format(
                              new Date(intervention.patientBirthDate),
                              "dd/MM/yyyy",
                            )}
                          </TableCell>
                          <TableCell>
                            {format(
                              new Date(intervention.interventionDate),
                              "dd/MM/yyyy",
                            )}
                          </TableCell>
                          <TableCell>{intervention.medicalActCode}</TableCell>
                          <TableCell>
                            <Stack direction="row">
                              {InterventionUtils.getAllConsentsStatus(
                                intervention.consents,
                              ).map(status => (
                                <MedicalStatus
                                  status={status}
                                  key={status}
                                  length={intervention.consents.length}
                                />
                              ))}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                  )}

                  {isFetching && <FetchingRows rows={rowsPerPage} />}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={medicalConsents.length === 0 ? 1 : medicalConsents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                borderTop: isPageFull()
                  ? "none"
                  : "1px solid rgba(224, 224, 244, 1)",
                "& p": { fontFamily: "Barlow, sans-serif" },
                "& .MuiTablePagination-displayedRows": {
                  "&::before": {
                    content: "''",
                    position: "absolute",
                    top: 0,
                    left: 199,
                    borderLeft: "1px solid #ECECEF",
                    height: "64px",
                  },
                },
                "& .MuiInputBase-root": {
                  "& span": {
                    position: "absolute",
                    left: 30,
                  },
                },
              }}
            />
          </Paper>
        </Grid>
        <Grid item sm={4} md={3} lg={2.5}>
          <ConsentDetailPanel
            currentHeight={currentHeight}
            selectedIntervention={selectedIntervention}
            sendEmailRelaunch={sendEmailRelaunch}
            isRelaunchSent={isRelaunchSent}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MedicalConsentsSearch;
