import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

const CAIHDrawerItem = props => {
  const { onClick, children } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        cursor: "pointer",
        fontSize: 14,
        letterSpacing: 0.3,
        color: "cobalt.ultramarine",
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        padding: "8px",
        width: "100%",
      }}
      onClick={onClick}
    >
      {children}
    </Grid>
  );
};

CAIHDrawerItem.defaultProps = {
  onClick: null,
};

CAIHDrawerItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default CAIHDrawerItem;
