import hubService from "./HubService";

const ConsentService = {
  getPatientByIds: (localIpp, healthFacilityId) => {
    const url = `serialNumber/Patient/code/patient/externalId/${localIpp}_${healthFacilityId}`;

    return hubService.request("GET", url, null, true);
  },
  getAllPatientGDPRConsents: (localIpp, healthFacilityId) => {
    const url = `consents?localIpp=${localIpp}&healthFacilityId=${healthFacilityId}&intentType=GDPR`;

    return hubService.request("GET", url);
  },
  updatePatientConsents: (consentId, body) => {
    const url = `consentValidation/${consentId}`;

    return hubService.request("POST", url, body);
  },
  sendEmailConfirmation: patientId => {
    const url = "consentNotifications";

    return hubService.request("POST", url, { patientId, intentType: "GDPR" });
  },
  getMedicalConsents: (
    healthFacilityId,
    medicalActCode,
    interventionDay,
    consentStatus,
  ) => {
    let medicalActCodeAsString = "";

    medicalActCode.forEach(code => {
      medicalActCodeAsString += `&medicalActCode=${code}`;
    });

    const url = `medicalConsentStatus?healthFacilityId=${healthFacilityId}&interventionDay=${interventionDay}${medicalActCodeAsString}&consentStatus=${consentStatus}`;

    return hubService.request("GET", url);
  },

  getMedicalActCode: healthFacilityId => {
    const url = `MEDICAL_CONSENT_TEMPLATE_MAPPING?k1=${healthFacilityId}&k2=**&k3=**`;

    return hubService.request("GET", url, null, false, true);
  },
  sendEmailRelaunch: patientId => {
    const url = "consentreminder";

    return hubService.request("POST", url, {
      patientId,
      intentType: "MEDICAL",
      isCreationNotification: true,
    });
  },
  getConsentPDFDoc: (id, ipp, healthFacilityId) =>
    hubService.request(
      "GET",
      `signedConsents?consentId=${id}&localIpp=${ipp}&healthFacilityId=${healthFacilityId}`,
    ),
};

export default ConsentService;
