import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import keycloak from "../utils/KeycloakPromise";
import Router from "../routes/Router";
import ClinicUtils from "../utils/ClinicUtils";
import UserInstitutionUtil from "../utils/UserInstitutionUtil";

import { KeycloakProvider } from "../context/KeycloakProvider";

function App() {
  const [userInstitution, setUserInstitution] =
    UserInstitutionUtil.useUserInstitution();
  const [codeEtab, setCodeEtab] = useState();
  const [consentAccess, setConsentAccess] = useState([]);
  const [isFetchingAccess, setIsFetchingAccess] = useState(true);
  const queryClient = new QueryClient();

  const initOptions = {
    checkLoginIframe: false,
    scope: "openid consent_access",
  };

  const tokenLogger = () => {
    keycloak.onTokenExpired = () => {
      keycloak.updateToken(60).catch(() => {
        keycloak.login({
          redirectUri: `${window.__ENV__.CAIH_DOMAIN}search`,
        });
      });
    };
  };
  const setUserEtab = e => {
    if (e === "onAuthSuccess") {
      keycloak
        .loadUserInfo()
        .then(userInfo => {
          const { institution, consent_access } = userInfo;

          // Récupération des infos de l'institution
          if (userInstitution[0] === "default") {
            setUserInstitution(institution);
          }

          const healthFacilityId =
            ClinicUtils.getCodeEtablissement(institution);

          setCodeEtab(healthFacilityId);
          setConsentAccess(consent_access.split(","));
        })
        .then(() => setIsFetchingAccess(false))
        .catch(() =>
          keycloak.logout({
            post_logout_redirect_uri: `${window.__ENV__.CAIH_DOMAIN}search`,
            id_token_hint: keycloak.idToken,
          }),
        );
    } else if (e === "onAuthLogout") {
      setCodeEtab(null);
    }
  };

  return (
    <KeycloakProvider
      authClient={keycloak}
      onTokens={tokenLogger}
      initOptions={initOptions}
      onEvent={setUserEtab}
    >
      <QueryClientProvider client={queryClient}>
        <Router
          codeEtab={codeEtab}
          setCodeEtab={setCodeEtab}
          consentAccess={consentAccess}
          isFetchingAccess={isFetchingAccess}
        />
      </QueryClientProvider>
    </KeycloakProvider>
  );
}

export default App;
