import React, { useState, useEffect } from "react";
import { isValid } from "date-fns";
import PropTypes from "prop-types";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DatePicker, PickersDay } from "@mui/x-date-pickers";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import Zoom from "@mui/material/Fade";

const CustomPickerDay = (_date, _selectedDates, pickersDayProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <PickersDay sx={{ fontFamily: "Barlow, sans-serif" }} {...pickersDayProps} />
);

const CalendarMenu = props => {
  const {
    currentChoices,
    setCurrentChoices,
    setIsInterventionDateValid,
    handleFocusOnInterventionField,
    setIsDisabled,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => setIsDisabled(isOpen), [isOpen, setIsDisabled]);

  const handleCalendarUpdate = newDate => {
    if (isValid(newDate))
      setCurrentChoices({
        ...currentChoices,
        date: newDate,
      });
  };

  const handleChoiceOnPicker = () => {
    setIsInterventionDateValid(true);
    handleFocusOnInterventionField();
  };

  return (
    <Box
      sx={{
        "& div[role=presentation]": {
          fontFamily: "Barlow, sans-serif",
          color: "cobalt.ultramarine",
          textTransform: "capitalize",
        },
      }}
    >
      <DatePicker
        label="Date d'intervention"
        required
        open={isOpen}
        disableHighlightToday
        value={currentChoices.date}
        views={["day"]}
        onChange={handleCalendarUpdate}
        renderDay={CustomPickerDay}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onError={event => setIsInterventionDateValid(event === null)}
        onAccept={handleChoiceOnPicker}
        disableOpenPicker
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={() => setIsOpen(!isOpen)}>
                <CobaltIcon
                  name="calendar"
                  style={{
                    fontSize: "16px",
                    color: theme.palette.cobalt.ultramarine,
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          placeholder: "jj/mm/aaaa",
          type: "text",
        }}
        renderInput={params => (
          <TextField
            fullWidth
            required
            onFocus={() => setIsOpen(false)}
            onChange={handleCalendarUpdate}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
          />
        )}
        TransitionComponent={Zoom}
        transitionDuration="500ms"
      />

      <Zoom in={isOpen}>
        <Box
          sx={{
            display: isOpen ? "flex" : "none",
            height: "360px",
            backgroundColor: "transparent",
            transitionDelay: "500ms",
          }}
        />
      </Zoom>
    </Box>
  );
};

CalendarMenu.propTypes = {
  setCurrentChoices: PropTypes.func.isRequired,
  currentChoices: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    interventions: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  }).isRequired,
  setIsInterventionDateValid: PropTypes.func.isRequired,
  handleFocusOnInterventionField: PropTypes.func.isRequired,
  setIsDisabled: PropTypes.func.isRequired,
};

export default CalendarMenu;
