import axios from "axios";

const URL = window.__ENV__.HUB_TOKEN_OPERATOR_REQUEST_URL;

const HubService = {
  request: (
    method,
    url,
    body = null,
    isBoxNumber = false,
    isRepository = false,
  ) => {
    return axios.post(URL, {
      method,
      url,
      body,
      isBoxNumber,
      isRepository,
    });
  },
};

export default HubService;
