import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Stack, Typography } from "@mui/material";

import ConsentDetailDownload from "./ConsentDetailDownload";

const ConsentDetailItem = props => {
  const { handleSelectedConsentDownload, consent } = props;
  // Gestion de la variation des RP d'un consentement à l'autre pour une même intervention
  const [hasLegalRepresentative, setHasLegalRepresentative] = useState(false);

  useMemo(() => {
    const rpList = consent.representativeLegalList ?? [];
    setHasLegalRepresentative(rpList.length > 0);
  }, [consent]);

  return (
    <Grid
      key={consent.id}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {/** Infos patient */}
      {!hasLegalRepresentative && (
        <ConsentDetailDownload
          handleSelectedConsentDownload={handleSelectedConsentDownload}
          consent={consent}
          title={consent.medicalConsentTemplateTitle}
          status={consent.status}
          id={consent.id}
          medicalActCode={consent.medicalActCode}
        />
      )}
      {/** 2RP */}
      {hasLegalRepresentative &&
        consent.representativeLegalList.map((rp, index) => (
          <ConsentDetailDownload
            key={`rp--${Number(index)}`}
            handleSelectedConsentDownload={handleSelectedConsentDownload}
            consent={consent}
            label={`Représentant légal n°${Number(index) + 1}`}
            title={consent.medicalConsentTemplateTitle}
            status={consent.status}
            id={consent.id}
            medicalActCode={consent.medicalActCode}
          />
        ))}
      {/** 1 RP + un second non déclaré */}
      {consent?.reasonAbsenceLegalRep && (
        <Stack sx={{ my: 1 }}>
          <Typography variant="subtitle2">
            Représentant légal n°2 non déclaré
          </Typography>

          <Stack direction="row" spacing={1} mt={1}>
            <Typography sx={{ color: "cobalt.lynch" }} variant="subtitle2">
              Raison
            </Typography>
            <Typography variant="body2">
              {consent?.reasonAbsenceLegalRep
                ? consent.reasonAbsenceLegalRep
                : "Non renseignée"}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Grid>
  );
};

ConsentDetailItem.defaultProps = {
  consent: {
    representativeLegalList: [],
  },
};

ConsentDetailItem.propTypes = {
  handleSelectedConsentDownload: PropTypes.func.isRequired,
  consent: PropTypes.shape({
    healthFacilityId: PropTypes.string.isRequired,
    medicalActCode: PropTypes.string.isRequired,
    medicalConsentTemplateTitle: PropTypes.string.isRequired,
    patientId: PropTypes.string.isRequired,
    reasonAbsenceLegalRep: PropTypes.string,
    interventionDate: PropTypes.string.isRequired,
    intentId: PropTypes.string.isRequired,
    medicalConsentTemplateNdrId: PropTypes.string.isRequired,
    serviceName: PropTypes.string,
    statusHistory: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ),
    intentType: PropTypes.string.isRequired,
    contraliaTransactionId: PropTypes.string,
    representativeLegalList: PropTypes.arrayOf(
      PropTypes.shape({
        civility: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        quality: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        mobileNumber: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ),
    id: PropTypes.string.isRequired,
    interventionId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    medicalConsentTemplateEventDate: PropTypes.string.isRequired,
    patientBirthDate: PropTypes.string.isRequired,
    patientLastName: PropTypes.string.isRequired,
    patientFirstName: PropTypes.string.isRequired,
    patientLocalIpp: PropTypes.string.isRequired,
  }),
};

export default ConsentDetailItem;
