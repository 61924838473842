import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";

const DetailItem = props => {
  const { label, content } = props;
  return (
    <Grid item xs={6}>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
        {content}
      </Typography>
    </Grid>
  );
};

DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
export default DetailItem;
