export const ROUTES = [
  {
    path: "/search",
    key: "search",
    status: "protected",
  },
  {
    path: "/consents",
    key: "consents",
    status: "protected",
  },
  {
    path: "/medical-consents",
    key: "medicalconsents",
    status: "protected",
  },
  {
    path: "/patient",
    key: "patient",
    status: "protected",
  },
  {
    path: "/consents/context",
    key: "context",
    status: "contextual",
  },
  {
    path: "/redirect",
    key: "redirect",
    status: "contextual",
  },
];

export default { ROUTES };
