import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import frLocale from "date-fns/locale/fr";
import { Paper, Stack, Box, Typography, Divider, Button } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const ConsentReminder = props => {
  const {
    selectedIntervention,
    sendEmailRelaunch,
    isRelaunchSent,
    hasLegalRepresentative,
  } = props;

  const lastRelaunchDate = () =>
    format(
      new Date(
        selectedIntervention.statusHistory[
          selectedIntervention.statusHistory.length - 1
        ].date,
      ),
      "dd/MM/yy",
      { locale: frLocale },
    );

  const generateConfirmationText = () => {
    if (isRelaunchSent) {
      const interventionDate = format(
        new Date(selectedIntervention.interventionDate),
        "dd/MM/yy",
        { locale: frLocale },
      );

      if (hasLegalRepresentative) {
        if (
          selectedIntervention.consents[0].representativeLegalList.length === 1
        ) {
          return `Le représentant légal va recevoir les consentements pour l'intervention du ${interventionDate}`;
        }
        return `Les représentants légaux vont recevoir les consentements pour l'intervention du ${interventionDate}`;
      }
      return `${selectedIntervention.patientFirstName} ${selectedIntervention.patientLastName} va recevoir ses consentements pour l'intervention du ${interventionDate}`;
    }

    return "";
  };

  return (
    <Paper
      sx={{
        p: "16px 24px 24px",
        boxShadow: 25,
      }}
    >
      <Stack
        sx={{
          textAlign: isRelaunchSent !== null ? "left" : "center",
          position: "relative",
          bottom: 0,
        }}
      >
        {isRelaunchSent === null ? (
          <>
            <Box>
              <Typography variant="body1" sx={{ color: "cobalt.lynch" }}>
                {`Dernière relance effectuée le ${lastRelaunchDate()}`}
              </Typography>
            </Box>
            <Divider
              sx={{
                margin: "15px -24px 24px",
              }}
            />
            <Button
              color="primary"
              variant="contained"
              startIcon={<CobaltIcon name="mail" />}
              onClick={() => sendEmailRelaunch(selectedIntervention.patientId)}
            >
              Relancer par e-mail
            </Button>
          </>
        ) : (
          <Stack>
            <Stack
              direction="row"
              sx={{
                color: isRelaunchSent ? "cobalt.greenText" : "cobalt.redText",
              }}
              pt={1.5}
            >
              <CobaltIcon
                name={isRelaunchSent ? "check" : "x"}
                style={{ marginRight: "8px" }}
              />
              <Typography variant="h5" color="inherit">
                {isRelaunchSent ? "Mail de relance envoyé" : "Echec de l'envoi"}
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{ color: "cobalt.lynch", mt: 3 }}>
              {generateConfirmationText()}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

ConsentReminder.defaultProps = {
  isRelaunchSent: null,
  hasLegalRepresentative: false,
};

//! Check PropTypes

ConsentReminder.propTypes = {
  selectedIntervention: PropTypes.shape({
    healthFacilityId: PropTypes.string,
    id: PropTypes.string,
    intentId: PropTypes.string,
    intentType: PropTypes.string,
    interventionDate: PropTypes.string,
    interventionId: PropTypes.string,
    medicalActCode: PropTypes.string,
    medicalConsentTemplateNdrId: PropTypes.string,
    medicalConsentTemplateCode: PropTypes.string,
    patientBirthDate: PropTypes.string,
    patientFirstName: PropTypes.string,
    patientId: PropTypes.string,
    patientLastName: PropTypes.string,
    patientLocalIpp: PropTypes.string,
    serviceName: PropTypes.string,
    status: PropTypes.string,
    statusHistory: PropTypes.arrayOf(
      PropTypes.shape({ status: PropTypes.string, date: PropTypes.string }),
    ),
    consents: PropTypes.arrayOf(
      PropTypes.shape({
        healthFacilityId: PropTypes.string,
        representativeLegalList: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    ),
  }).isRequired,
  sendEmailRelaunch: PropTypes.func.isRequired,
  isRelaunchSent: PropTypes.bool,
  hasLegalRepresentative: PropTypes.bool,
};

export default ConsentReminder;
