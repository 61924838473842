import { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import ConsentService from "../services/ConsentService";

const useMedicalActCode = healthFacilityId => {
  const [medicalActs, setMedicalActs] = useState([]);
  const [medicalActCodes, setMedicalActCodes] = useState([]);
  const [medicalActNames, setMedicalActNames] = useState([]);
  const { getMedicalActCode } = ConsentService;

  useQuery({
    queryKey: ["actCode", healthFacilityId],
    queryFn: () => getMedicalActCode(healthFacilityId),
    onSuccess: res => {
      setMedicalActCodes(res.data.map(i => ({ code: i.k2, label: i.k3 })));
      setMedicalActNames(res.data.map(i => i.k3));
      setMedicalActs(res.data);
    },
    onError: () => setMedicalActCodes([]),
    enabled: healthFacilityId !== "" && medicalActCodes.length === 0,
  });

  const getCodeByName = name => medicalActs.find(m => m.k3 === name).k2;

  return {
    medicalActCodes,
    medicalActNames,
    getCodeByName,
    setMedicalActCodes,
  };
};

useMedicalActCode.propTypes = {
  healthFacilityId: PropTypes.string.isRequired,
};
export default useMedicalActCode;
