import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useKeycloak } from "context/KeycloakProvider";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Container,
  Grid,
  Box,
  useMediaQuery,
  Stack,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import Modal from "./Modal";
import CAIHSnackbar from "./CAIHSnackbar";
import CAIHFooter from "./CAIHFooter";
import CAIHDrawer from "./CAIHDrawer";
import ClinicService from "../services/ClinicService";
import AccessUtils from "../utils/AccessUtils";

const LogoLayout = props => {
  const [logoData, setLogoData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [clinicLegalNotice, setClinicLegalNotice] = useState("");
  const [clinicGtu, setClinicGtu] = useState("");
  const { children, codeEtab, consentAccess } = props;
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const canAccessToRGPD = useMemo(
    () => AccessUtils.isPageAccessible(consentAccess, "GDPR"),
    [consentAccess],
  );

  const canAccessToMedical = useMemo(
    () => AccessUtils.isPageAccessible(consentAccess, "MEDICAL"),
    [consentAccess],
  );

  useEffect(() => {
    setCurrentPage(history.location.pathname);
  }, [location, history]);

  useEffect(() => {
    const getClinicInfos = () => {
      ClinicService.getClinicDetail(codeEtab)
        .then(cResponse => {
          const { value } = cResponse.data[0];
          const clinicDetails = JSON.parse(value);
          setClinicLegalNotice(clinicDetails.legalNotice);
          setClinicGtu(clinicDetails.gtu);
        })
        .catch(() => {
          setClinicLegalNotice("");
          setClinicGtu("");
        });

      ClinicService.getClinicImages(codeEtab)
        .then(response => {
          const { value } = response.data[0];
          const picturesObject = JSON.parse(value);
          const { logo } = picturesObject;
          setLogoData(logo);
        })
        .catch(() => setLogoData(null));
    };

    if (codeEtab !== null) {
      getClinicInfos(codeEtab);
    }
  }, [codeEtab]);

  const handleClick = () => {
    keycloak.logout({
      post_logout_redirect_uri: `${window.__ENV__.CAIH_DOMAIN}search`,
      id_token_hint: keycloak.idToken,
    });
  };

  const handleClickOnMenu = () => setIsOpen(!isOpen);

  const handleChangeOnMenu = target => {
    setCurrentPage(target);
    return history.push(target);
  };

  return (
    <Box
      sx={{
        overflowX: "hidden",
        position: "relative",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          overflowX: "hidden",
          position: "relative",
          boxShadow:
            "0 0 1px 0 rgba(0, 0, 45, 0.04), 0 0 2px 0 rgba(0, 0, 45, 0.06), 0 4px 8px 0 rgba(0, 0, 46, 0.04)",
          flexGrow: 1,
        }}
      >
        <AppBar position="static" sx={{ backgroundColor: "cobalt.white" }}>
          <Toolbar disableGutters sx={{ height: isMobile ? 64 : 88 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" justifyContent="flex-start">
                {logoData !== null && logoData !== "" && (
                  <img
                    src={`data:image/jpeg;base64,${logoData}`}
                    alt="logo"
                    style={{
                      height: isMobile ? 32 : 48,
                      width: "auto",
                      marginLeft: 32,
                    }}
                  />
                )}
              </Stack>
              {keycloak.authenticated && (
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ marginRight: isMobile ? "20px" : "30px" }}
                  onClick={isMobile ? handleClickOnMenu : undefined}
                  spacing={2}
                >
                  {isMobile ? (
                    <CobaltIcon
                      name="menu"
                      style={{
                        color: theme.palette.cobalt.ultramarine,
                        fontSize: "24px",
                        height: "auto",
                        margin: "8px",
                      }}
                    />
                  ) : (
                    <>
                      {canAccessToRGPD &&
                        currentPage !== "/search" &&
                        currentPage !== "/patient" && (
                          <Button
                            mr={4}
                            variant="delta"
                            onClick={() => handleChangeOnMenu("/search")}
                          >
                            Consentements RGPD
                          </Button>
                        )}

                      {canAccessToMedical &&
                        currentPage !== "/medical-consents" && (
                          <Button
                            mr={4}
                            variant="delta"
                            onClick={() =>
                              handleChangeOnMenu("/medical-consents")
                            }
                          >
                            Consentements Médicaux
                          </Button>
                        )}

                      <Button
                        variant="delta"
                        onClick={handleClick}
                        startIcon={<CobaltIcon name="log-out" />}
                      >
                        Déconnexion
                      </Button>
                    </>
                  )}
                </Stack>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
        <CAIHDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          gtu={clinicGtu}
          legalNotice={clinicLegalNotice}
          currentPage={currentPage}
        />
      </Box>
      <Toolbar />
      <Box mb={12}>
        <Container maxWidth={currentPage === "/medical-consents" ? "xl" : "md"}>
          {children}
        </Container>
      </Box>
      <CAIHFooter legalNotice={clinicLegalNotice} gtu={clinicGtu} />
      <CAIHSnackbar />
      <Modal />
    </Box>
  );
};

LogoLayout.propTypes = {
  children: PropTypes.node,
  consentAccess: PropTypes.arrayOf(PropTypes.string).isRequired,
  codeEtab: PropTypes.string,
};

LogoLayout.defaultProps = {
  children: null,
  codeEtab: null,
};

export default withTranslation()(LogoLayout);
