import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CAIHLoader from "../../components/CAIHLoader";
import ConsentService from "../../services/ConsentService";
import ContextAPIService from "../../services/ContextAPIService";

function Redirect(props) {
  const { setCodeEtab } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState();
  const [errorLabel, setErrorLabel] = useState();

  useEffect(() => {
    const redirectionHash = history.location.hash;

    const extractAPIToken = hash => {
      const tokenWithoutHash = hash.substring(1);
      // Si le hash contient d'autres informations que le token, récupère seulement ce dernier
      const token = tokenWithoutHash.split("&")[0];
      return token;
    };

    const loadContextInfos = userId => {
      setIsLoading(true);

      ContextAPIService.getContextInfo(userId)
        .then(ContextResponse => {
          const infos = ContextResponse.data;
          const { operator } = infos;

          ConsentService.getPatientByIds(infos.localIpp, infos.healthFacilityId)
            .then(CsResponse => {
              setIsLoading(false);

              const { data } = CsResponse;
              const { value } = data;

              const { healthFacilityId } = value;
              setCodeEtab(healthFacilityId);

              history.push("/consents/context", {
                user: value,
                operator,
              });
            })
            .catch(() => {
              setIsLoading(false);
              setErrorLabel(t("context:errors.common"));
            });
        })
        .catch(error => {
          const { response } = error;
          const { status, data } = response;
          setIsLoading(false);
          if (status === 404 && data.error === "Not Found") {
            setErrorLabel(t("context:errors.expiration"));
          } else {
            setErrorLabel(t("context:errors.common"));
          }
        });
    };

    const token = extractAPIToken(redirectionHash);
    loadContextInfos(token);
  }, [history, setCodeEtab, t, history.location.hash]);

  if (isLoading) {
    return <CAIHLoader label="Chargement des données en cours" />;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Typography variant="body2" sx={{ color: "cobalt.bleuDigital100" }}>
        {errorLabel}
      </Typography>
    </Grid>
  );
}

export default Redirect;

Redirect.propTypes = {
  setCodeEtab: PropTypes.func.isRequired,
};
