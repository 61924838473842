import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Button,
  TableCell,
  TableRow,
  Stack,
} from "@mui/material";
import SearchIcon from "../../icons/med_consents_search.svg";
import SearchFailIcon from "../../icons/med_consents_search_fail.svg";

const EmptySearch = props => {
  const {
    setSelectedIntervention,
    isFiltered,
    setIsModalOpen,
    isModalOpen,
    disabledSearch,
    height,
  } = props;

  useEffect(() => setSelectedIntervention(null));

  return (
    <TableRow align="center">
      <TableCell
        align="center"
        colSpan={12}
        sx={{ borderBottom: "none", height }}
      >
        <Stack flexDirection="column" alignItems="center">
          <Box>
            <img
              src={isFiltered ? SearchFailIcon : SearchIcon}
              alt="search_icon"
              style={{ height: "121px", width: "121px" }}
            />
          </Box>
          <Typography variant="body1" sx={{ color: "cobalt.lynch", mt: 3 }}>
            {isFiltered
              ? "Cette recherche n'a donné aucun résultat"
              : "Initier une recherche en complétant les filtres de base"}
          </Typography>
          <Button
            sx={{ marginTop: "32px" }}
            variant="outlined"
            size="small"
            onClick={() => setIsModalOpen(!isModalOpen)}
            disabled={disabledSearch}
          >
            {isFiltered
              ? "Lancer une nouvelle recherche"
              : "Lancer une recherche filtrée"}
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

EmptySearch.propTypes = {
  setSelectedIntervention: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  disabledSearch: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
};

export default EmptySearch;
