import hubService from "./HubService";

const ContextAPIService = {
  getContextInfo: key => {
    const url = `contexts/${key}?inputMode=assisted`;
    return hubService.request("GET", url);
  },
};

export default ContextAPIService;
