import React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import ShieldIcon from "../../icons/shield.svg";
import PadlockIcon from "../../icons/padlock.svg";

const ConsentNote = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Paper
      sx={{
        borderRadius: "8px",
        backgroundColor: "#eef0fb",
        paddingTop: isMobile ? "16px" : "32px",
        paddingBottom: isMobile ? "16px" : "32px",
        paddingLeft: isMobile ? "16px" : "64px",
        paddingRight: isMobile ? "16px" : "64px",
        boxShadow: "none",
      }}
    >
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ marginBottom: isMobile ? "18px" : "0px" }}
        >
          <Grid
            container
            direction={isMobile ? "column" : "row"}
            justifyContent={isMobile ? "center" : "flex-start"}
            alignItems="center"
            sx={{
              flexWrap: "nowrap",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <img
              src={ShieldIcon}
              alt="preference_icon"
              style={{ height: "auto", width: "55px" }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "cobalt.ultramarine",
                marginLeft: isMobile ? "0px" : "12px",
                marginTop: isMobile && "12px",
              }}
            >
              Vous pourrez modifier ultérieurement tout consentement accepté ou
              refusé
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            direction={isMobile ? "column" : "row"}
            justifyContent={isMobile ? "center" : "flex-start"}
            alignItems="center"
            sx={{
              flexWrap: "nowrap",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <img
              src={PadlockIcon}
              alt="preference_icon"
              style={{ height: "auto", width: "55px" }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "cobalt.ultramarine",
                marginLeft: isMobile ? "0px" : "12px",
                marginTop: isMobile && "12px",
              }}
            >
              Pour toute question sur vos droits concernant vos données,
              rapprochez-vous du Délégué à la Protection des Données de votre
              établissement
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConsentNote;
