import React, {
  PropsWithChildren,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";

import { KeycloakContext, KeycloakContextType } from "./KeycloakContext";
import Keycloak, { KeycloakInitOptions } from "keycloak-js";

type KeycloakProviderProps = {
  authClient: Keycloak;
  initOptions: KeycloakInitOptions;
  onEvent: (
    e: "onReady" | "onAuthSuccess" | "onAuthRefreshSuccess" | "onAuthLogout",
  ) => void;
  onTokens: (e: "onTokenExpired") => void;
  onError: (
    e: "onAuthError" | "onAuthRefreshError" | "onInitError",
    error?: unknown,
  ) => void;
};

export const useKeycloak = (): KeycloakContextType | null =>
  useContext(KeycloakContext);

export const KeycloakProvider = (
  props: PropsWithChildren<KeycloakProviderProps>,
): JSX.Element => {
  const { authClient, initOptions, onEvent, onError, onTokens, children } =
    props;
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  // onEvent callback
  authClient.onReady = (): void => onEvent("onReady");
  authClient.onAuthSuccess = (): void => onEvent("onAuthSuccess");
  authClient.onAuthRefreshSuccess = (): void => onEvent("onAuthRefreshSuccess");
  authClient.onAuthLogout = (): void => onEvent("onAuthLogout");
  //onTokens
  authClient.onTokenExpired = (): void => onTokens("onTokenExpired");
  //onError callback
  authClient.onAuthError = (e): void => onError("onAuthError", e);
  authClient.onAuthRefreshError = (): void => onError("onAuthRefreshError");

  const initKeycloak = useCallback(() => {
    const init = async () => {
      try {
        const authenticated = await authClient.init(initOptions);
        setIsAuthenticated(authenticated);
      } catch (e) {
        onError("onInitError", e);
      } finally {
        setInitialized(true);
      }
    };

    if (!initialized) {
      return init();
    }
    return null;
  }, [authClient]);

  useEffect(() => {
    initKeycloak();
  }, []);

  return (
    <KeycloakContext.Provider
      value={{
        keycloak: authClient,
        initialized,
        isAuthenticated,
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};
