/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Slide, DialogTitle, DialogContent } from "@mui/material";
import { Dialog } from "@docaposte-agility/da-design-system";

import ModalUtil from "../utils/ModalUtil";

const Transition = React.forwardRef(function getTransitionComponent(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CustomModal = () => {
  const [modal, setModal] = ModalUtil.useModal();
  const { isOpen, content, title, maxWidth, backdropReason } = modal;

  const closeModal = () =>
    setModal({
      isOpen: false,
      title: "",
      content: null,
      action: null,
      backdropReason: null,
    });

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      TransitionComponent={Transition}
      open={isOpen}
      onClose={e => e.type && backdropReason && closeModal()}
    >
      {title !== null && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers>{content}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
