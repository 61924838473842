import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  InputLabel,
  Grid,
  Typography,
  Checkbox,
  ClickAwayListener,
} from "@mui/material";

import Zoom from "@mui/material/Fade";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const InterventionsMenu = props => {
  const {
    medicalActCode,
    currentChoices,
    setCurrentChoices,
    isInterventionFieldFocused,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [medicalActCodeSearch, setMedicalActCodeSearch] = useState([]);

  useEffect(() => {
    // Set la recherche avec tous les actes médicaux disponibles par défault
    setMedicalActCodeSearch(medicalActCode);

    // Conditionne l'ouverture du volet Intervention de la modale
    if (
      currentChoices.interventions.length === 0 &&
      currentChoices.date !== null
    )
      setIsOpen(isInterventionFieldFocused);
  }, [
    isInterventionFieldFocused,
    currentChoices.interventions.length,
    currentChoices.date,
    medicalActCode,
  ]);

  const handleMedicalSearch = event => {
    const searchValue = event.target.value;

    if (!searchValue.length) return setMedicalActCodeSearch(medicalActCode);

    const medicalActs = medicalActCode.filter(mac =>
      mac.label.toLowerCase().includes(searchValue.toLowerCase()),
    );

    return setMedicalActCodeSearch(medicalActs);
  };

  const handleMedicalActCodeSelection = medicalAct => {
    const hadActAlreadySelected = currentChoices.interventions.includes(
      medicalAct.code,
    );
    if (hadActAlreadySelected) {
      const newSelectedInterventions = currentChoices.interventions.filter(
        item => item !== medicalAct.code,
      );
      return setCurrentChoices({
        ...currentChoices,
        interventions: [...newSelectedInterventions],
      });
    }
    return setCurrentChoices({
      ...currentChoices,
      interventions: [...currentChoices.interventions, medicalAct.code],
    });
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <FormControl fullWidth>
        <InputLabel required id="interventions">
          Nom de l&apos;intervention
        </InputLabel>
        <Select
          labelId="interventions"
          id="interventions-select"
          multiple
          fullWidth
          open={isOpen}
          value={currentChoices.interventions}
          onFocus={() => setIsOpen(!isOpen)}
          onMouseDown={() => !isOpen && document.activeElement.blur()}
          displayEmpty
          renderValue={selection => {
            if (selection.length === 0) {
              return "Sélectionnez le type d'intervention";
            }
            if (selection.length > 3) {
              return `${selection.length} interventions sélectionnées`;
            }
            return medicalActCode
              .filter(mac => currentChoices.interventions.includes(mac.code))
              .map(mac => mac.label)
              .join(", ");
          }}
          MenuProps={{
            sx: { display: "none" },
          }}
        />

        <Zoom in={isOpen} addEndListener={() => document.activeElement.blur()}>
          <Box
            sx={{
              display: isOpen ? "flex" : "none",
              flexDirection: "column",
              height:
                medicalActCode.length <= 5
                  ? 80 + 40 * medicalActCode.length
                  : 250,
              backgroundColor: "transparent",
              transitionDelay: "500ms",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Rechercher par intervention"
              fullWidth
              onChange={handleMedicalSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CobaltIcon name="search" />
                  </InputAdornment>
                ),
              }}
              sx={{ padding: "20px" }}
            />
            {medicalActCodeSearch.map(intervention => (
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                sx={{ pl: "20px" }}
                key={intervention.code}
                value={intervention.label}
              >
                <Checkbox
                  sx={{ mr: "8px" }}
                  checked={currentChoices.interventions.includes(
                    intervention.code,
                  )}
                  onClick={() => {
                    handleMedicalActCodeSelection(intervention);
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    mt: "0px",
                    mb: "0px",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleMedicalActCodeSelection(intervention);
                  }}
                >
                  {intervention.label}
                </Typography>
              </Grid>
            ))}
          </Box>
        </Zoom>
      </FormControl>
    </ClickAwayListener>
  );
};

InterventionsMenu.propTypes = {
  medicalActCode: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setCurrentChoices: PropTypes.func.isRequired,
  currentChoices: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    interventions: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  }).isRequired,
  isInterventionFieldFocused: PropTypes.bool.isRequired,
};

export default InterventionsMenu;
