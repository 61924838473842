import React from "react";
import PropTypes from "prop-types";
import {
  useMediaQuery,
  Grid,
  Box,
  Divider,
  Typography,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TntImg from "../images/tnt-doca.png";

const CAIHFooter = props => {
  const { legalNotice, gtu } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const generateFooterItem = (item, label) => (
    <Box
      sx={{ userSelect: "none", cursor: "pointer" }}
      onClick={() => window.open(item)}
    >
      <Typography variant="body2">{label}</Typography>
    </Box>
  );

  const legalNoticeItem = generateFooterItem(legalNotice, "Mentions légales");
  const cguItem = generateFooterItem(gtu, "CGU");

  if (!isMobile) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: 48,
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          divider={
            <Divider orientation="vertical" flexItem sx={{ height: "20px" }} />
          }
          spacing={1.5}
          sx={{ marginLeft: "84px" }}
        >
          {!!legalNotice.length && legalNoticeItem}
          {!!gtu.length && cguItem}
        </Stack>

        <Stack direction="row" justifyContent="flex-end">
          <img
            src={TntImg}
            alt="TNT"
            style={{ width: "100px", height: "auto", marginRight: 72 }}
          />
        </Stack>
      </Grid>
    );
  }

  return null;
};

CAIHFooter.defaultProps = {
  legalNotice: "",
  gtu: "",
};

CAIHFooter.propTypes = {
  legalNotice: PropTypes.string,
  gtu: PropTypes.string,
};

export default CAIHFooter;
