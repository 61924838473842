import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { CobaltIcon } from "@docaposte-agility/da-design-system";
import CAIHCard from "components/CAIHCard";
import TextSkeleton from "components/TextSkeleton";

import ConsentDisplayDistribution from "components/consent/ConsentDisplayDistribution";
import { Consent, ConsentStatus } from "types/Consents";

type ConsentGatheringProps = {
  isLoading: boolean;
  validateConsents: Consent[];
  newConsents: Consent[];
  refusedConsents: Consent[];
  completedConsents: Consent[];
  originalyUnavailableConsents: Consent[];
  changeConsentFunc: (consent: Consent, type: ConsentStatus) => void;
  acceptEverythingFunc?: () => void;
  refuseEverythingFunc?: () => void;
};
function ConsentGathering(props: ConsentGatheringProps): JSX.Element {
  const { t } = useTranslation();
  const [selectedConsents, setSelectedConsents] = useState<Consent[]>([]);

  const {
    isLoading,
    validateConsents,
    newConsents,
    refusedConsents,
    completedConsents,
    changeConsentFunc,
    acceptEverythingFunc,
    refuseEverythingFunc,
    originalyUnavailableConsents,
  } = props;

  const handleChange = (newConsent: Consent, type: ConsentStatus) => {
    if (!selectedConsents.includes(newConsent)) {
      setSelectedConsents([...selectedConsents, newConsent]);
    }

    changeConsentFunc(newConsent, type);
  };

  const isPendingConsent = (consent: Consent): boolean =>
    originalyUnavailableConsents.includes(consent);

  const isCompletedConsent = (consent: Consent): boolean =>
    completedConsents.includes(consent) && validateConsents.includes(consent);

  const isRefusedConsent = (consent: Consent): boolean =>
    refusedConsents.includes(consent) && validateConsents.includes(consent);

  return (
    <CAIHCard hasPadding={false} cardContentNoPadding>
      {isLoading ? (
        <Box p={4}>
          <TextSkeleton lines={4} width={"100"} />
        </Box>
      ) : (
        <Box>
          <Box
            p={4}
            sx={{ borderBottom: 1, borderBottomColor: "cobalt.grey10" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Box mr={1}>
                <Button
                  onClick={() => acceptEverythingFunc && acceptEverythingFunc()}
                  variant="CAIHLite"
                  startIcon={<CobaltIcon name="check" />}
                >
                  {t("consents:consentGathering.acceptAll")}
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => refuseEverythingFunc && refuseEverythingFunc()}
                  variant="CAIHLite"
                  startIcon={<CobaltIcon name="x" />}
                >
                  {t("consents:consentGathering.refuseAll")}
                </Button>
              </Box>
            </Grid>
          </Box>
          <ConsentDisplayDistribution
            isPendingConsent={isPendingConsent}
            isCompletedConsent={isCompletedConsent}
            isRefusedConsent={isRefusedConsent}
            consents={newConsents}
            handleChange={handleChange}
          />
        </Box>
      )}
    </CAIHCard>
  );
}

export default ConsentGathering;
