import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

const CAIHCard = props => {
  const { title, children, hasGutter, hasPadding, cardContentNoPadding } =
    props;

  return (
    <Box mb={hasGutter ? 2 : 1}>
      <Card
        sx={{
          borderRadius: "8px",
          padding: hasPadding ? "26px" : "0px",
          boxShadow:
            "0px 4px 8px rgba(0, 0, 46, 0.04), 0px 0px 2px rgba(0, 0, 45, 0.06), 0px 0px 1px rgba(0, 0, 45, 0.04)",
        }}
        width="100%"
      >
        {title !== null && <CardHeader title={title} />}
        <CardContent sx={{ padding: cardContentNoPadding ? "0px" : "16px" }}>
          {children}
        </CardContent>
      </Card>
    </Box>
  );
};

CAIHCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  hasGutter: PropTypes.bool,
  hasPadding: PropTypes.bool,
  cardContentNoPadding: PropTypes.bool,
};

CAIHCard.defaultProps = {
  title: null,
  hasGutter: false,
  hasPadding: true,
  cardContentNoPadding: false,
};

export default CAIHCard;
