import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";

import CAIHPrediceError from "components/CAIHPrediceError";
import ConsentKnowMore from "components/consent/ConsentKnowMore";
import ModalUtil from "utils/ModalUtil";
import { Consent } from "types/Consents";
import { ConsentDisplayDistributionProps } from "./ConsentDisplayDistribution";

interface ConsentItemProps
  extends Pick<
    ConsentDisplayDistributionProps,
    | "handleChange"
    | "isCompletedConsent"
    | "isPendingConsent"
    | "isRefusedConsent"
  > {
  consent: Consent;
  isUnique: boolean;
}

const ConsentItem = ({
  consent,
  isPendingConsent,
  isCompletedConsent,
  isRefusedConsent,
  handleChange,
  isUnique,
}: ConsentItemProps): JSX.Element => {
  const { t } = useTranslation();
  const setModal = ModalUtil.useModal()[1];

  const getKnowMore = (consent: Consent) => {
    setModal({
      isOpen: true,
      title: t("consents:consentGathering.knowMore"),
      content: (
        <ConsentKnowMore
          title={consent.intentLabel}
          description={consent.intentDescription}
        />
      ),
      backdropReason: "click",
    });
  };

  if (
    consent.status === "MAINCARE_READ_ERROR" &&
    consent.intentCode === "PREDICE"
  )
    return (
      <Grid item xs={12} sm={6} md={7} lg={8} m={4}>
        <CAIHPrediceError />
      </Grid>
    );

  return (
    <Box
      key={consent.intentId}
      sx={{
        paddingLeft: "32px",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingRight: "32px",
        "&:not(:last-child)": {
          borderBottom: 1,
          borderBottomColor: isUnique ? "transparent" : "cobalt.grey10",
        },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={7} lg={8}>
          <Typography
            variant="body2"
            sx={{
              color: isPendingConsent(consent)
                ? "cobalt.lynch"
                : "cobalt.ultramarine",
            }}
          >
            {consent.intentLabel}
          </Typography>
          {consent.intentDescription !== null &&
          consent.intentDescription !== "" &&
          isPendingConsent(consent) ? (
            <Typography
              variant="body2"
              sx={{
                color: "cobalt.lynch",
                textDecoration: "underline",
              }}
            >
              {t("consents:consentGathering.knowMore")}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              onClick={() => getKnowMore(consent)}
              sx={{
                color: "cobalt.bleuDigital100",
                cursor: "pointer",
              }}
            >
              {t("consents:consentGathering.knowMore")}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Button
              disabled={isPendingConsent(consent)}
              className={isCompletedConsent(consent) ? "selected" : ""}
              variant="CAIHConsentButton"
              onClick={() => handleChange(consent, "COMPLETED")}
            >
              {t("common:yes")}
            </Button>
            <Button
              variant="CAIHConsentButton"
              disabled={isPendingConsent(consent)}
              className={isRefusedConsent(consent) ? "refused" : ""}
              onClick={() => handleChange(consent, "REFUSED")}
            >
              {t("common:no")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConsentItem;
