import React, { useState, useEffect, useCallback } from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useKeycloak } from "context/KeycloakProvider";
import { Box, Grid, Typography, Button } from "@mui/material";
import ConsentService from "../../services/ConsentService";
import RequestUtils from "../../utils/RequestUtils";
import ConsentsUtils from "../../utils/ConsentsUtils";
import SnackbarUtil from "../../utils/SnackbarUtil";
import ConsentGathering from "../../components/consent/ConsentGathering";
import ConsentNote from "../../components/consent/ConsentNote";
import ConsentContent from "../../components/consent/ConsentContent";
import ClinicService from "../../services/ClinicService";

function Consents() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { keycloak } = useKeycloak();
  let { state } = location;

  const setSnackbar = SnackbarUtil.useSnackbar()[1];
  const [isLoading, setIsLoading] = useState(true);
  const [isSendingConsent, setIsSendingConsent] = useState(false);
  const [consentsPatientId, setConsentsPatientId] = useState("");
  const [newConsents, setNewConsents] = useState([]);
  const [newContent, setNewContent] = useState([]);
  const [completedConsents, setCompletedConsents] = useState([]);
  const [refusedConsents, setRefusedConsents] = useState([]);
  const [originalyRefusedConsents, setOriginalyRefusedConsents] = useState([]);
  const [originalyCompletedConsents, setOriginalyCompletedConsents] = useState(
    [],
  );
  const [originalyUnavailableConsents, setOriginalyUnavailableConsents] =
    useState([]);
  const [validateConsents, setValidateConsents] = useState([]);
  const [, setStatusError] = useState([]);

  if (state === undefined) {
    state = {
      user: { localIpp: undefined, healthFacilityId: undefined },
      operator: undefined,
    };
  }

  const { user, operator } = state;
  const { localIpp, healthFacilityId } = user;

  const openAlert = (message, type) => {
    setSnackbar({ isOpen: true, message, type, autoHideDuration: 3000 });
  };

  const getAllPatientConsents = useCallback(
    (ipp, idEtab) => {
      setIsLoading(true);

      const addError = (message, type) => {
        setSnackbar({ isOpen: true, message, type, autoHideDuration: null });
      };

      ClinicService.getClinicGDPRInformationNote(idEtab)
        .then(cinResponse => {
          const { data } = cinResponse;
          const GDPRInfoNote = JSON.parse(data[0].value);
          setNewContent(GDPRInfoNote.description);
        })
        .catch(() => setNewContent([]));

      ConsentService.getAllPatientGDPRConsents(ipp, idEtab)
        .then(response => {
          const rawConsents = response.data;

          const consents = rawConsents.filter(
            consent => consent.intentType === "GDPR",
          );

          const userRequestedConsents = [];
          const userRefusedConsents = [];
          const userCompletedConsents = [];
          const userValidateConsents = [];
          const userUnavailableConsents = [];
          consents.forEach(consent => {
            if (consent.status === "REQUESTED") {
              userRequestedConsents.push(consent);
            } else if (consent.status === "MAINCARE_READ_ERROR") {
              userUnavailableConsents.push(consent);
            } else {
              userValidateConsents.push(consent);

              if (consent.status === "COMPLETED") {
                userCompletedConsents.push(consent);
              }

              if (consent.status === "REFUSED") {
                userRefusedConsents.push(consent);
              }
            }
          });

          if (consents.length > 0) {
            setConsentsPatientId(consents[0].patientId);
          }

          setNewConsents(ConsentsUtils.orderConsents(consents));
          setCompletedConsents(userCompletedConsents);
          setRefusedConsents(userRefusedConsents);
          setOriginalyRefusedConsents(userRefusedConsents);
          setOriginalyCompletedConsents(userCompletedConsents);
          setValidateConsents(userValidateConsents);
          setOriginalyUnavailableConsents(userUnavailableConsents);
          setIsLoading(false);
        })
        .catch(error => {
          const { response } = error;
          const { status, data } = response;

          if (status === 404 && data.errorMessage === "CONSENT_NOT_FOUND") {
            addError(t("layout:alert.consent_not_found"), "error");
          } else {
            addError(t("layout:alert.error"), "error");
          }
        });
    },
    [setSnackbar, t],
  );

  useEffect(() => {
    if (localIpp !== undefined || healthFacilityId !== undefined) {
      getAllPatientConsents(localIpp, healthFacilityId);
    } else {
      history.push("/");
    }
  }, [history, localIpp, healthFacilityId, getAllPatientConsents]);

  const handleChangeNew = (consent, type) => {
    if (type === "COMPLETED") {
      if (!completedConsents.includes(consent)) {
        if (refusedConsents.includes(consent)) {
          setRefusedConsents(
            refusedConsents.filter(value => value !== consent),
          );
        }
        setCompletedConsents([...completedConsents, consent]);
      }
    }

    if (type === "REFUSED") {
      if (completedConsents.includes(consent)) {
        setCompletedConsents(
          completedConsents.filter(value => value !== consent),
        );
      }
      setRefusedConsents([...refusedConsents, consent]);
    }

    if (!validateConsents.includes(consent)) {
      setValidateConsents([...validateConsents, consent]);
    }
  };

  /**
   * @name acceptEverything
   * @description Accepte tous les consentements
   */
  const acceptEverything = () => {
    const hasToBeAccepted = [];
    const hasToBeValidate = [];

    newConsents.forEach(newConsent => {
      // Fait basculer le consentement dans la partie completed s'il l'est pas déjà
      if (
        !completedConsents.includes(newConsent) &&
        newConsent.status !== "MAINCARE_READ_ERROR"
      ) {
        hasToBeAccepted.push(newConsent);
      }

      // Si il n'est pas dans la liste des consentements déjà selectionnés
      if (
        !validateConsents.includes(newConsent) &&
        newConsent.status !== "MAINCARE_READ_ERROR"
      ) {
        // On l'ajoute à la liste des consentements selectionnés
        hasToBeValidate.push(newConsent);
      }
    });

    // Update des states
    setValidateConsents([...validateConsents, ...hasToBeValidate]);
    setRefusedConsents(
      refusedConsents.filter(item => !hasToBeAccepted.includes(item)),
    );
    setCompletedConsents([...completedConsents, ...hasToBeAccepted]);
  };

  /**
   * @name refuseEverything
   * @description Refuse tous les consentements
   */
  const refuseEverything = () => {
    const hasToBeRefused = [];
    const hasToBeValidate = [];

    newConsents.forEach(newConsent => {
      // Fait basculer le consentement dans la partie refused s'il l'est pas déjà
      if (
        !refusedConsents.includes(newConsent) &&
        newConsent.status !== "MAINCARE_READ_ERROR"
      ) {
        hasToBeRefused.push(newConsent);
      }

      // Si il n'est pas dans la liste des consentements déjà selectionnés
      if (
        !validateConsents.includes(newConsent) &&
        newConsent.status !== "MAINCARE_READ_ERROR"
      ) {
        // On l'ajoute à la liste des consentements selectionnés
        hasToBeValidate.push(newConsent);
      }
    });

    // Update des states
    setValidateConsents([...validateConsents, ...hasToBeValidate]);
    setCompletedConsents(
      completedConsents.filter(item => !hasToBeRefused.includes(item)),
    );
    setRefusedConsents([...refusedConsents, ...hasToBeRefused]);
  };

  /**
   * @name isDisabledContent
   * @description Vérifie si le consentement doit être ajouté aux éléments refusés
   * @param {string} intentId
   * @param {object} body
   * @returns {boolean} Vrai si le consentement n'est pas déjà dans la liste des consentements refusés et que son status est "REFUSED"
   */
  const isDisabledContent = (intentId, body) => {
    if (
      originalyRefusedConsents.findIndex(
        validateConsent => validateConsent.intentId === intentId,
      ) === -1
    ) {
      return body.status === "REFUSED";
    }

    return false;
  };

  /**
   * @name isCompletedContent
   * @description Vérifie si le consentement doit être ajouté aux éléments complétés
   * @param {string} intentId
   * @param {object} body
   * @returns {boolean} Vrai si le consentement n'est pas déjà dans la liste des consentements acceptés et que son status est "COMPLETED"
   */
  const isCompletedContent = (intentId, body) => {
    if (
      originalyCompletedConsents.findIndex(
        validateConsent => validateConsent.intentId === intentId,
      ) === -1
    ) {
      return body.status === "COMPLETED";
    }

    return false;
  };

  /**
   * @name didRefusedConsentsChanged
   * @description Verifie si les consentements refusés ont été modifiés
   * @returns {boolean} Vrai si le state de consentements refusés est identique en taille et contenus au state original
   */
  const didRefusedConsentsChanged = () => {
    // Vérifie si les consentements refusés sont dans la liste des consentements refusés originaux
    const refusedConsentsDidntChange = refusedConsents.every(r =>
      originalyRefusedConsents.includes(r),
    );
    // Vérifie si la longueur des deux tableaux  est identique pour déterminer si des consentements refusés n'ont pas été retirés
    let refusedConsentsLengthDidntChange = true;
    if (refusedConsents.length !== originalyRefusedConsents.length) {
      refusedConsentsLengthDidntChange = false;
    }

    return refusedConsentsDidntChange && refusedConsentsLengthDidntChange;
  };

  /**
   * @name didCompletedConsentsChanged
   * @description Vérifie si les consentements acceptés ont été modifiés
   * @returns {boolean} Vrai si le state de consentements acceptés est identique en taille et contenu au state original
   */
  const didCompletedConsentsChanged = () => {
    // Vérifie si les consentements refusés sont dans la liste des consentements refusés originaux
    const completedConsentsDidntChange = completedConsents.every(r =>
      originalyCompletedConsents.includes(r),
    );
    // Vérifie si la longueur des deux tableaux  est identique pour déterminer si des consentements refusés n'ont pas été retirés
    let completedConsentsLengthDidntChange = true;
    if (completedConsents.length !== originalyCompletedConsents.length) {
      completedConsentsLengthDidntChange = false;
    }
    return completedConsentsDidntChange && completedConsentsLengthDidntChange;
  };

  /**
   * @name buttonIsDisabled
   * @description Verifie si il faut griser les boutons
   * @returns {boolean} Vrai si les states de consentements refusés et acceptés sont identiques en taille et contenu au state original
   */
  const buttonIsDisabled = () =>
    didRefusedConsentsChanged() && didCompletedConsentsChanged();

  /**
   * @name hasChanged
   * @description Vérifie si l'objet a changé pour ne pas refaire une modification
   * @param {string} status
   * @param {object} body
   * @param {object} consent
   * @param {array} newDisabledConsents
   * @param {array} newCompletedConsents
   * @returns {boolean} Vrai si le statut initial de l'objet diffère de celui du body ou si l'objet figure dans les tableaux de consentements en arguments
   */
  const hasChanged = (
    status,
    body,
    consent,
    newDisabledConsents,
    newCompletedConsents,
  ) => {
    if (body.status === status) {
      if (body.status === "REFUSED" && newDisabledConsents.includes(consent)) {
        return true;
      }
      if (
        body.status === "COMPLETED" &&
        newCompletedConsents.includes(consent)
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  /**
   * @name isPropertyValid
   * @description Vérifie si la propriété d'un objet existe, n'est pas nulle et n'est pas une string vide
   * @param {object} item
   * @param {string} property
   * @returns {boolean} Vrai si les trois conditions sont vérifiées
   */

  const isPropertyValid = (item, property) => {
    if (
      Object.prototype.hasOwnProperty.call(item, property) &&
      item[property] !== "" &&
      item[property] !== null
    ) {
      return true;
    }
    return false;
  };

  /**
   * @name isUserInfoComplete
   * @description Vérifie la validité des infos patient
   * @returns {boolean} Vrai si les deux propriétés sont vraies
   */

  const isUserInfoComplete = () =>
    isPropertyValid(user, "mobileNumber") && isPropertyValid(user, "email");

  /**
   * @name isUserInfoValid
   * @description Si les informations patient sont signalées incomplètes, vérification de celle du représentant légal
   * @returns {boolean} Vrai si les infos patient ou celles de son représentant sont valides
   */
  const isUserInfoValid = () => {
    if (isUserInfoComplete() === false) {
      const { ...legalRepresentative } = user.legalRepresentative;
      return (
        isPropertyValid(legalRepresentative, "mobileNumber") &&
        isPropertyValid(legalRepresentative, "email")
      );
    }
    return true;
  };

  const submitConsent = async () => {
    const promises = [];
    const results = [];
    const newDisabledConsents = [];
    const newCompletedConsents = [];

    setIsSendingConsent(true);

    newConsents.forEach(newConsent => {
      const { patientId, intentId, status } = newConsent;
      const consentId = `${patientId}_${intentId}`;

      const body = {
        status: completedConsents.includes(newConsent)
          ? "COMPLETED"
          : "REFUSED",
        consentCollectorCategoryCode: "HEALTH_FACILITY",
        consentCollector: {
          ...operator,
          healthFacilityId,
        },
      };
      // Si ce consentement est refusé
      if (isDisabledContent(intentId, body)) {
        newDisabledConsents.push(newConsent);
      }

      // Si ce consentement est accepté
      if (isCompletedContent(intentId, body)) {
        newCompletedConsents.push(newConsent);
      }

      // Si le consentement doit être modifié et ne fait pas partie des consentements non accessibles et que les informations patients necessaires sont complétées
      if (
        hasChanged(
          status,
          body,
          newConsent,
          newDisabledConsents,
          newCompletedConsents,
        ) &&
        !originalyUnavailableConsents.includes(newConsent)
      ) {
        promises.push(ConsentService.updatePatientConsents(consentId, body));
      }
    });

    await Promise.all(
      promises.map(p =>
        p
          .then(result => {
            const { status } = result;
            results.push(status);
          })
          .catch(e => {
            if (e.response.status) {
              results.push(e.response.status);
            }
          }),
      ),
    );

    // Si l'un des status est un succes et que les infos de l'utilisateur ou de son responsable légal sont complètes, on envoie un mail
    if (results.includes(200) && isUserInfoValid()) {
      ConsentService.sendEmailConfirmation(consentsPatientId)
        .then(emailResponse => {
          const emailStatus = emailResponse.status;

          // Si le mail a été envoyé
          if (RequestUtils.isSuccess(emailStatus)) {
            // Si tous les promises ont retourné un success
            if (results.every(pStatus => pStatus === 200)) {
              setIsSendingConsent(false);
              setOriginalyRefusedConsents([...refusedConsents]);
              setOriginalyCompletedConsents([...completedConsents]);
              setValidateConsents([...refusedConsents, ...completedConsents]);

              openAlert(t("layout:alert.success"), "success");
            } else {
              const statusWithErrorArray = [];

              results.forEach(statusResult => {
                if (statusResult !== 200) {
                  statusWithErrorArray.push(statusResult);
                }
              });

              setIsLoading(true);
              setStatusError(statusWithErrorArray);
            }
          }
        })
        .catch(() => {
          if (results.every(pStatus => pStatus === 200)) {
            setOriginalyRefusedConsents([...refusedConsents]);
            setOriginalyCompletedConsents([...completedConsents]);
            setValidateConsents([...refusedConsents, ...completedConsents]);

            openAlert(t("layout:alert.mail"), "error");
          } else {
            openAlert(t("layout:alert.error"), "error");
          }
          setIsSendingConsent(false);
        });
    } else if (
      // Si toutes les requêtes ont abouti mais que les infos utilisateurs ne sont pas complètes
      results.every(pStatus => pStatus === 200) &&
      !isUserInfoValid()
    ) {
      setOriginalyRefusedConsents([...refusedConsents]);
      setOriginalyCompletedConsents([...completedConsents]);
      setValidateConsents([...refusedConsents, ...completedConsents]);
      openAlert(t("layout:alert.success"), "success");
      setIsSendingConsent(false);
    } else {
      const statusWithErrorArray = [];

      results.forEach(statusResult => {
        if (statusResult !== 200) {
          statusWithErrorArray.push(statusResult);
        }
      });

      setIsSendingConsent(false);
      openAlert(t("layout:alert.error"), "error");
      setStatusError(statusWithErrorArray);
    }
  };

  /**
   * @name everythingIsChecked
   * @description Permet de savoir si tous les consentements ont été choisi
   * @returns {boolean} Vrai si tous les consentement ont été choisi
   */
  const everythingIsChecked = () =>
    newConsents.length ===
    validateConsents.length + originalyUnavailableConsents.length;

  return (
    <Box>
      {!isLoading && (
        <Grid
          container
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={5}
        >
          <Typography variant="h1">{`${user.firstName} ${user.lastName}`}</Typography>
          <Typography variant="h4" sx={{ color: "cobalt.lynch", ml: "20px" }}>
            {format(new Date(user.birthDate), "dd.MM.yyyy")}
          </Typography>
        </Grid>
      )}
      {newContent === null ||
      (newContent.length === 1 && newContent[0] === "") ? (
        <Box />
      ) : (
        <Box>
          <Box mb={2} ml={5}>
            <Typography variant="h3">{t("consents:noteLabel")}</Typography>
          </Box>
          <ConsentContent isLoading={isLoading} content={newContent} />
        </Box>
      )}
      <Box>
        <Box mt={4} mb={2} ml={5}>
          <Typography variant="h3">{t("consents:consentsLabel")}</Typography>
        </Box>
        <ConsentGathering
          isLoading={isLoading}
          newConsents={newConsents}
          completedConsents={completedConsents}
          refusedConsents={refusedConsents}
          validateConsents={validateConsents}
          changeConsentFunc={handleChangeNew}
          acceptEverythingFunc={acceptEverything}
          refuseEverythingFunc={refuseEverything}
          originalyUnavailableConsents={originalyUnavailableConsents}
          revokedDisabled
        />
      </Box>
      <Box mt={5} mb={2} ml={5}>
        <Typography variant="h3">{t("consents:infosLabel")}</Typography>
      </Box>
      <Box mb={5}>
        <ConsentNote />
      </Box>
      <Box mb={2}>
        <Grid container justifyContent="flex-end" alignItems="center">
          {keycloak.authenticated && (
            <Button
              sx={{ marginRight: "24px" }}
              variant="text"
              onClick={() => history.push("/")}
            >
              Retour
            </Button>
          )}
          <Button
            disabled={
              buttonIsDisabled() ||
              !everythingIsChecked() ||
              isSendingConsent ||
              isLoading
            }
            onClick={() => submitConsent()}
          >
            {t("common:validate")}
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}

export default Consents;
