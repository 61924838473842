import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Router, Switch, Redirect } from "react-router-dom";
import { useKeycloak } from "../context/KeycloakProvider";
import ProtectedRoute from "./ProtectedRoute";
import ContextRoute from "./ContextRoute";

// Routes
import Search from "./components/Search";
import Consents from "./components/Consents";
import Redirection from "./components/Redirect";
import MedicalConsentsSearch from "./components/MedicalConsentsSearch";

// Dépendances spécifiques aux routes
import history from "./history";
import { ROUTES } from "./routes";

import Layout from "../components/Layout";
import AccessUtils from "../utils/AccessUtils";

const Routes = props => {
  const { codeEtab, setCodeEtab, consentAccess, isFetchingAccess } = props;
  const { initialized } = useKeycloak();
  const [accessValidation, setAccessValidation] = useState(false);
  const [accessibleRoutes, setAccessibleRoutes] = useState(ROUTES);

  // TODO - Refacto
  // Gestion des accès aux différentes recherches
  useEffect(() => {
    // Vérifie que l'utilisateur a au moins un accès renseigné
    const isConsentAccessValid = AccessUtils.isAccessValid(consentAccess);
    // Vérifie les accès par recherche
    const isAccessibleGDPR = AccessUtils.isPageAccessible(
      consentAccess,
      "GDPR",
    );
    const isAccessibleMedical = AccessUtils.isPageAccessible(
      consentAccess,
      "MEDICAL",
    );

    if (isConsentAccessValid) {
      const currentRoutes = [];

      ROUTES.forEach(route => {
        // Si l'utilisateur a les droits GDPR, il a accès à la route de recherche standard
        if (isAccessibleGDPR && route.key === "search") {
          currentRoutes.push(route);
        }
        // Si l'utilisateur a les droits medicaux, il a accès à la route de recherche de consentements médicaux
        if (isAccessibleMedical && route.key === "medicalconsents") {
          currentRoutes.push(route);
        }
        // Si l'utilisateur a les droits medicaux, il a accès à la route de recherche de consentements médicaux
        // Autres cas
        if (route.key !== "search" && route.key !== "medicalconsents") {
          currentRoutes.push(route);
        }
      });

      setAccessibleRoutes(currentRoutes);
    }
    setAccessValidation(isConsentAccessValid);
  }, [consentAccess]);

  const setRedirectionLink = () => {
    const routesIncludeSearch = accessibleRoutes.filter(
      route => route.key === "search",
    );
    const routesIncludeMedicalSearch = accessibleRoutes.filter(
      route => route.key === "medicalconsents",
    );

    if (routesIncludeSearch.length > 0) {
      return <Redirect to="/search" />;
    }
    if (routesIncludeMedicalSearch.length > 0) {
      return <Redirect to="/medical-consents" />;
    }
    return null;
  };

  const getRouteComponent = route => {
    switch (route.key) {
      case "search":
        return Search;
      case "consents":
        return Consents;
      case "medicalconsents":
        return MedicalConsentsSearch;
      case "redirect":
        return Redirection;
      case "context":
        return Consents;
      default:
        break;
    }
    return null;
  };

  const renderRoute = route => {
    const component = getRouteComponent(route);

    switch (route.status) {
      case "protected":
        return (
          <ProtectedRoute
            exact
            path={route.path}
            key={route.key}
            accessValidation={accessValidation}
            consentAccess={consentAccess}
            isFetchingAccess={isFetchingAccess}
            component={component}
          />
        );
      case "contextual":
        return (
          <ContextRoute
            path={route.path}
            key={route.key}
            component={component}
            setCodeEtab={setCodeEtab}
          />
        );

      default:
        break;
    }
    return null;
  };

  if (initialized) {
    return (
      <Router history={history}>
        <Layout codeEtab={codeEtab} consentAccess={consentAccess}>
          <Switch>
            {accessibleRoutes.map(renderRoute)}
            {setRedirectionLink()}
          </Switch>
        </Layout>
      </Router>
    );
  }
  return null;
};

Routes.defaultProps = {
  codeEtab: null,
};

Routes.propTypes = {
  codeEtab: PropTypes.string,
  setCodeEtab: PropTypes.func.isRequired,
  consentAccess: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFetchingAccess: PropTypes.bool.isRequired,
};

export default Routes;
