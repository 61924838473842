import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Box } from "@mui/material";

const FilterItem = props => {
  const { label, children } = props;

  return (
    <Grid
      item
      flexGrow={1}
      sx={{
        backgroundColor: "cobalt.light",
        padding: "20px 16px",
        my: "8px",
        borderRadius: "8px",
      }}
    >
      <Typography variant="subtitle2">{label}</Typography>
      <Box>{children}</Box>
    </Grid>
  );
};

FilterItem.defaultProps = {
  label: "",
};

FilterItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FilterItem;
