import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Formiz, useForm } from "@formiz/core";
import { isRequired } from "@formiz/validations";
import { useKeycloak } from "context/KeycloakProvider";
import { useQuery } from "react-query";
import format from "date-fns/format";

import { Box, Grid, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Alert, CobaltIcon } from "@docaposte-agility/da-design-system";

import CAIHCard from "../../components/CAIHCard";
import CAIHField from "../../components/CAIHField";

import PatientImg from "../../images/patient.png";
import PatientFoundImg from "../../images/patientFound.png";

import ConsentService from "../../services/ConsentService";

import UserInstitutionUtil from "../../utils/UserInstitutionUtil";
import ClinicUtils from "../../utils/ClinicUtils";
import SnackbarUtil from "../../utils/SnackbarUtil";

function Search() {
  const { t } = useTranslation();
  const history = useHistory();
  const searchForm = useForm();
  const { keycloak } = useKeycloak();
  const theme = useTheme();
  const setSnackbar = SnackbarUtil.useSnackbar()[1];
  const [healthFacilityId, setHealthFacilityId] = useState(null);
  const [user, setUser] = useState(null);
  const [userInstitution] = UserInstitutionUtil.useUserInstitution();
  const [idp, setIdp] = useState("");
  const [userNotFound, setUserNotFound] = useState(false);
  const [searchUser, setSearchUser] = useState(false);

  const isInstitutionValid = () => userInstitution.length >= 1;

  const openAlert = (message, type) => {
    setSnackbar({ isOpen: true, message, type, autoHideDuration: 3000 });
  };

  useEffect(() => {
    const initOperator = async () => {
      try {
        const { institution, sub } = await keycloak.loadUserInfo();
        const healthFacilityId = ClinicUtils.getCodeEtablissement(institution);
        setIdp(sub);
        setHealthFacilityId(healthFacilityId);
      } catch (e) {
        setIdp(null);
        setHealthFacilityId(null);
        openAlert(t("layout:alert.error"), "error");
      }
    };
    return initOperator();
  }, []);

  const { isFetching } = useQuery({
    queryKey: ["consents"],
    retry: false,
    queryFn: () =>
      ConsentService.getPatientByIds(
        searchForm.values.localIpp,
        healthFacilityId,
      ),
    onSettled: () => setSearchUser(false),
    onSuccess: ({ data }) => {
      const { value } = data;

      setUser(value);
      setUserNotFound(false);
    },
    onError: () => {
      setUserNotFound(true);
      setUser(null);
      openAlert(t("layout:alert.error"), "error");
    },
    enabled: searchUser,
  });

  const handleSubmit = () => setSearchUser(true);

  return (
    <Box>
      <Typography variant="h1">{t("searchOperator:title")}</Typography>
      <Box mt={7}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={7}>
            <CAIHCard>
              {user !== null ? (
                <Box>
                  <Box
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => setUser(null)}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <CobaltIcon
                          name="chevron-left"
                          style={{
                            color: theme.palette.cobalt.bleuDigital100,
                            fontSize: "18px",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "cobalt.bleuDigital100" }}
                        >
                          {t("common:return")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="subtitle1">
                      {t("searchOperator:searchLabel")}
                    </Typography>
                    <Box mt={3}>
                      <Box
                        sx={{
                          backgroundColor: "cobalt.bleuDigital05",
                          borderRadius: "12px",
                          paddingTop: "24px",
                          paddingBottom: "24px",
                          paddingLeft: "40px",
                          paddingRight: "80px",
                          width: "100%",
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push("/consents", {
                            user,
                            operator: { idpId: idp },
                          });
                        }}
                      >
                        <Box
                          sx={{
                            width: "80px",
                            height: "80px",
                            position: "absolute",
                            right: "0px",
                            top: "7px",
                            lineHeight: "105px",
                            textAlign: "center",
                          }}
                        >
                          <CobaltIcon
                            name="chevron-right"
                            style={{
                              fontSize: "40px",
                              color: "cobalt.ultramarine",
                            }}
                          />
                        </Box>
                        <Typography variant="h3">{`${user.firstName} ${user.lastName}`}</Typography>
                        <Typography variant="h4" sx={{ color: "cobalt.lynch" }}>
                          {format(new Date(user.birthDate), "dd.MM.yyyy")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Typography variant="subtitle1">
                    {t("searchOperator:findLabel")}
                  </Typography>
                  <Formiz connect={searchForm} onValidSubmit={handleSubmit}>
                    <Box mt={6}>
                      <CAIHField
                        required
                        name="localIpp"
                        label={t("searchOperator:ippLabel")}
                        placeholder="XXXXXX"
                        onChange={() => setUserNotFound(false)}
                        validations={[
                          {
                            rule: isRequired(),
                            message: t("form:error.required"),
                          },
                        ]}
                      />
                      {userNotFound && (
                        <Box mt={2}>
                          <Alert severity="error">
                            {t("searchOperator:alertContent")}
                          </Alert>
                        </Box>
                      )}
                    </Box>
                    <Box mt={6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                      >
                        <Button
                          type="submit"
                          disabled={
                            !isInstitutionValid() ||
                            !searchForm.isValid ||
                            isFetching
                          }
                          onClick={() => handleSubmit()}
                          endIcon={<CobaltIcon name="chevron-right" />}
                        >
                          {t("searchOperator:search")}
                        </Button>
                      </Grid>
                    </Box>
                  </Formiz>
                </Box>
              )}
            </CAIHCard>
            {!isInstitutionValid() && (
              <Alert severity="error">
                {t("searchOperator:errorInstitution")}
              </Alert>
            )}
          </Grid>
          <Grid item md={5}>
            <Box display={{ xs: "none", md: "block" }}>
              {user !== null ? (
                <img
                  src={PatientFoundImg}
                  alt="Patient"
                  style={{ width: "100%", height: "auto" }}
                />
              ) : (
                <img
                  src={PatientImg}
                  alt="Patient"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Search;
