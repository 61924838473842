const ClinicUtils = {
  getCodeEtablissement: institution => {
    const strToSplit = institution.filter(item => item.includes("institution"));
    const slashSplittedString = strToSplit[0].split("/");
    const underscoreSplittedString = slashSplittedString.pop().split("_");

    return underscoreSplittedString.pop();
  },
};

export default ClinicUtils;
