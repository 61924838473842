import React from "react";
import PropTypes from "prop-types";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";

const FetchingRows = props => {
  const { rows } = props;

  return [...Array(rows)].map((e, i) => (
    <TableRow key={`false-row-${i.toString()}`}>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
    </TableRow>
  ));
};

FetchingRows.propTypes = {
  rows: PropTypes.number.isRequired,
};

export default FetchingRows;
