import React from "react";
import { Snackbar, Alert } from "@mui/material";
import SnackbarUtil from "../utils/SnackbarUtil";

const CAIHSnackbar = () => {
  const [snackbar, setSnackbar] = SnackbarUtil.useSnackbar();
  const { isOpen, message, type, autoHideDuration } = snackbar;

  const closeSnackbar = () => {
    setSnackbar({ isOpen: false });
  };

  return (
    <Snackbar
      open={isOpen}
      message={message}
      autoHideDuration={autoHideDuration}
      onClose={() => closeSnackbar()}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={type} sx={{ fontFamily: "Barlow, sans-serif" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CAIHSnackbar;
