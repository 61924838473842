/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { useKeycloak } from "context/KeycloakProvider";
import { Alert, Stack, Typography } from "@mui/material";
import CAIHLoader from "../components/CAIHLoader";

const ProtectedRoute = props => {
  const {
    consentAccess,
    accessValidation,
    isFetchingAccess,
    component: Component,
    ...route
  } = props;
  const { keycloak, isAuthenticated } = useKeycloak();

  useEffect(() => {
    if (!isAuthenticated) {
      keycloak.login({
        redirectUri: `${window.__ENV__.CAIH_DOMAIN}search`,
      });
    }
  }, [isAuthenticated, accessValidation]);

  if (isFetchingAccess) {
    return <CAIHLoader />;
  }

  if (!isAuthenticated || !accessValidation) {
    return (
      <Stack sx={{ alignItems: "center" }}>
        <Alert severity="error" sx={{ width: "fit-content" }}>
          <Typography variant="subtitle2" sx={{ ml: 2, color: "cobalt.lynch" }}>
            Vous n&apos;avez pas les droits nécessaires pour accéder à cette
            page
          </Typography>
        </Alert>
      </Stack>
    );
  }

  return (
    <Route
      exact
      render={routeProps => (
        <Component {...routeProps} consentAccess={consentAccess} />
      )}
      {...route}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  consentAccess: PropTypes.arrayOf(PropTypes.string).isRequired,
  accessValidation: PropTypes.bool.isRequired,
  isFetchingAccess: PropTypes.bool.isRequired,
};

export default ProtectedRoute;
