import React, { useState } from "react";
import PropTypes from "prop-types";
import { useField } from "@formiz/core";
import {
  Box,
  TextField,
  IconButton,
  Tooltip,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CAIHField = props => {
  const { errorMessage, id, isValid, isSubmitted, setValue } = useField(props);
  const theme = useTheme();

  const {
    fullWidth,
    type,
    defaultValue,
    label,
    required,
    disabled,
    placeholder,
    toolTip,
  } = props;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      {label && (
        <Box mb={2}>
          <Typography variant="body1">
            {label}
            {toolTip && (
              <Tooltip title={toolTip.content} placement="right" arrow>
                <IconButton
                  aria-label="tooltip"
                  size="small"
                  disableRipple
                  disableFocusRipple
                >
                  {toolTip.icon}
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Box>
      )}
      <TextField
        variant="outlined"
        error={showError}
        helperText={showError ? errorMessage : ""}
        id={id}
        type={type}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={e => setValue(e.target.value)}
        onBlur={() => setIsTouched(true)}
        fullWidth={fullWidth || isMobile}
        sx={{
          "& input": {
            height: "24px",
            width: "261px",
          },
          "& label": {
            marginBottom: "16px",
          },
        }}
      />
    </Box>
  );
};

CAIHField.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  toolTip: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    content: PropTypes.string.isRequired,
  }),
};

CAIHField.defaultProps = {
  disabled: false,
  required: false,
  fullWidth: false,
  type: "text",
  defaultValue: "",
  label: "",
  placeholder: "",
  toolTip: null,
};

export default CAIHField;
