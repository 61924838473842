import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Drawer, Grid, Box, Divider, Button, Stack } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { useKeycloak } from "context/KeycloakProvider";
import CAIHDrawerItem from "./CAIHDrawerItem";
import TntImg from "../images/tnt-doca.png";

const CAIHDrawer = props => {
  const { isOpen, gtu, legalNotice, currentPage, setIsOpen } = props;

  const { keycloak } = useKeycloak();
  const history = useHistory();

  const handleClick = () =>
    keycloak.logout({
      post_logout_redirect_uri: `${window.__ENV__.CAIH_DOMAIN}search`,
      id_token_hint: keycloak.idToken,
    });

  const switchResearchButton = () => {
    setIsOpen(false);
    if (currentPage === "/medical-consents") {
      history.push("/search");
    } else {
      history.push("/medical-consents");
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{ backgroundColor: "rgba(102, 109, 146, 0.3)" }}
      PaperProps={{
        sx: {
          width: "80vw",
          padding: "88px 24px 24px 24px",
        },
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "80%" }}
      >
        <Stack>
          <CAIHDrawerItem>
            <Button variant="delta" onClick={switchResearchButton}>
              {currentPage === "/medical-consents"
                ? "Consentements RGPD"
                : "Consentements Médicaux"}
            </Button>
          </CAIHDrawerItem>

          {keycloak.authenticated && (
            <CAIHDrawerItem>
              <Button
                variant="delta"
                onClick={handleClick}
                startIcon={<CobaltIcon name="log-out" />}
              >
                Déconnexion
              </Button>
            </CAIHDrawerItem>
          )}
        </Stack>
        <Stack direction="column">
          {keycloak.authenticated && (!!legalNotice.length || !!gtu.length) && (
            <Divider
              orientation="horizontal"
              sx={{
                height: "1px",
                width: "157px",
                alignSelf: "center",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            />
          )}
          {!!legalNotice.length && (
            <CAIHDrawerItem onClick={() => window.open(legalNotice)}>
              <Box component="span">Mentions légales</Box>
            </CAIHDrawerItem>
          )}
          {!!gtu.length && (
            <CAIHDrawerItem onClick={() => window.open(gtu)}>
              <Box component="span">CGU</Box>
            </CAIHDrawerItem>
          )}
        </Stack>
        <CAIHDrawerItem>
          <img
            src={TntImg}
            alt="TNT"
            style={{ width: 110, height: "auto", marginTop: 32 }}
          />
        </CAIHDrawerItem>
      </Grid>
    </Drawer>
  );
};

CAIHDrawer.defaultProps = {
  gtu: "",
  legalNotice: "",
  setIsOpen: null,
};

CAIHDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  gtu: PropTypes.string,
  legalNotice: PropTypes.string,
  currentPage: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func,
};

export default CAIHDrawer;
