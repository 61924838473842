import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

const CAIHLoader = props => {
  const { t } = useTranslation();
  let { label } = props;
  label = label === null ? t("common:loading") : label;

  return (
    <Grid container alignItems="center" justifyContent="center">
      <CircularProgress />
      <Box ml={2} sx={{ fontFamily: "Barlow, sans-serif" }}>
        {label}
      </Box>
    </Grid>
  );
};

CAIHLoader.propTypes = {
  label: PropTypes.string,
};

CAIHLoader.defaultProps = {
  label: null,
};

export default CAIHLoader;
