import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import ConsentItem from "components/consent/ConsentItem";
import { Consent, ConsentStatus } from "types/Consents";

type ConsentManagementFunc = (consent: Consent) => boolean;

export type ConsentDisplayDistributionProps = {
  consents: Consent[];
  handleChange: (consent: Consent, type: ConsentStatus) => void;
  isPendingConsent: ConsentManagementFunc;
  isRefusedConsent: ConsentManagementFunc;
  isCompletedConsent: ConsentManagementFunc;
};
const ConsentDisplayDistribution = ({
  consents,
  handleChange,
  isPendingConsent,
  isRefusedConsent,
  isCompletedConsent,
}: ConsentDisplayDistributionProps): JSX.Element => {
  const { t } = useTranslation();
  const NO_OPPO_VALUE = "NO_OPPO";

  const noOppoConsent: Consent[] = consents.filter(
    consent =>
      consent.intentMode !== undefined && consent.intentMode === NO_OPPO_VALUE,
  );
  const regularConsents: Consent[] = consents.filter(
    consent =>
      consent.intentMode === undefined || consent.intentMode !== NO_OPPO_VALUE,
  );

  const haveOppositionalConsents: boolean = noOppoConsent.length > 0;
  const haveRegularConsents: boolean = regularConsents.length > 0;

  return (
    <>
      {haveOppositionalConsents && (
        <>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 4,
                backgroundColor: "cobalt.bleuDigital05",
                borderRadius: 0,
              }}
              elevation={0}
            >
              <Typography variant="h4" component="p">
                {t("consents:noOppoNotice")}
              </Typography>
            </Paper>
          </Grid>

          {noOppoConsent.map(consent => (
            <ConsentItem
              key={consent.id}
              consent={consent}
              isPendingConsent={isPendingConsent}
              isCompletedConsent={isCompletedConsent}
              isRefusedConsent={isRefusedConsent}
              handleChange={handleChange}
              isUnique={noOppoConsent.length === 1}
            />
          ))}
        </>
      )}

      {haveRegularConsents && (
        <>
          {haveOppositionalConsents && (
            <Grid item xs={12} p={0}>
              <Paper
                sx={{
                  mt: 0,
                  p: 4,
                  backgroundColor: "cobalt.bleuDigital05",
                  borderRadius: 0,
                }}
                elevation={0}
              >
                <Typography variant="h4" component="p">
                  {t("consents:regularConsentsNotice")}
                </Typography>
              </Paper>
            </Grid>
          )}
          {regularConsents.map(consent => (
            <ConsentItem
              key={consent.id}
              consent={consent}
              isPendingConsent={isPendingConsent}
              isCompletedConsent={isCompletedConsent}
              isRefusedConsent={isRefusedConsent}
              handleChange={handleChange}
              isUnique={noOppoConsent.length === 1}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ConsentDisplayDistribution;
