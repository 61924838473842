import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const MedicalStatus = props => {
  const { status, length } = props;

  const getStatus = () => (status === "REQUESTED" ? "En attente" : "Signé");

  const getStatusWithNumber = () => {
    if (status === "REQUESTED") {
      return `${length} en attente`;
    }
    if (length === 1) {
      return `${length} signé`;
    }
    return `${length} signés`;
  };
  const getBgColor = () => (status === "REQUESTED" ? "#FEF4F5" : "#F4FAF6");

  const getColor = () => (status === "REQUESTED" ? "#D00000" : "#4A801A");

  return (
    <Box
      sx={{
        width: "fit-content",
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "4px",
        paddingBottom: "6px",
        borderRadius: "4px",
        fontSize: "12px",
        lineHeight: "120%",
        fontFamily: "Barlow",
        backgroundColor: getBgColor(),
        color: getColor(),
        margin: "4px",
      }}
    >
      {length === 0 ? getStatus() : getStatusWithNumber()}
    </Box>
  );
};

MedicalStatus.defaultProps = {
  length: 0,
};

MedicalStatus.propTypes = {
  status: PropTypes.string.isRequired,
  length: PropTypes.number,
};

export default MedicalStatus;
