import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { Dialog } from "@docaposte-agility/da-design-system";
import CalendarMenu from "./CalendarMenu";
import InterventionsMenu from "./InterventionsMenu";
import StatusMenu from "./StatusMenu";
import FilterItem from "./FilterItem";

const FilterModal = props => {
  const {
    setIsModalOpen,
    isModalOpen,
    medicalActCode,
    filterCriterias,
    setFilterCriterias,
    resetAllFilters,
  } = props;

  const [currentChoices, setCurrentChoices] = useState(filterCriterias);
  const [isInterventionDateValid, setIsInterventionDateValid] = useState(
    currentChoices.date !== null,
  );

  const [isInterventionFieldFocused, setIsInterventionFieldFocused] =
    useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const handleFocusOnInterventionField = () =>
    setIsInterventionFieldFocused(true);

  const handleResearch = () => {
    setFilterCriterias(currentChoices);
    setIsModalOpen(false);
  };

  const resetChoices = () => {
    resetAllFilters();
    setCurrentChoices({
      date: null,
      interventions: [],
      status: "",
    });
  };

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="lg"
      scroll="body"
      onClose={() => setIsModalOpen(false)}
      sx={{
        "& .MuiDialogTitle-root": {
          p: 3,
        },
        "& .MuiDialogContent-root": {
          p: 3,
        },
        "& .MuiDialogActions-root": {
          p: 3,
        },
      }}
      PaperProps={{
        sx: {
          height: "none",
          maxHeight: "none",
        },
      }}
    >
      <DialogTitle>Recherche filtrée</DialogTitle>
      <DialogContent dividers>
        <FilterItem>
          <CalendarMenu
            setFilterCriterias={setFilterCriterias}
            currentChoices={currentChoices}
            setCurrentChoices={setCurrentChoices}
            setIsInterventionDateValid={setIsInterventionDateValid}
            handleFocusOnInterventionField={handleFocusOnInterventionField}
            setIsDisabled={setIsDisabled}
          />
        </FilterItem>
        <FilterItem>
          <InterventionsMenu
            medicalActCode={medicalActCode}
            setFilterCriterias={setFilterCriterias}
            currentChoices={currentChoices}
            setCurrentChoices={setCurrentChoices}
            isInterventionFieldFocused={isInterventionFieldFocused}
            setIsInterventionFieldFocused={setIsInterventionFieldFocused}
            isDisabled={isDisabled}
          />
        </FilterItem>
        <FilterItem>
          <StatusMenu
            setFilterCriterias={setFilterCriterias}
            currentChoices={currentChoices}
            setCurrentChoices={setCurrentChoices}
          />
        </FilterItem>
        <Grid container justifyContent="flex-end" sx={{ mt: 5, width: "100%" }}>
          <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
            *Champs obligatoires
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          borderTop: "1px solid",
          borderTopColor: "cobalt.grey10",
          backgroundColor: "cobalt.white",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <Button
          disabled={
            currentChoices.interventions.length === 0 ||
            !isInterventionDateValid ||
            currentChoices.date === null
          }
          onClick={resetChoices}
          variant="text"
        >
          Réinitialiser
        </Button>
        <Button
          onClick={handleResearch}
          disabled={
            currentChoices.interventions.length === 0 ||
            !isInterventionDateValid ||
            currentChoices.date === null
          }
          variant="contained"
        >
          Afficher les résultats
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FilterModal.propTypes = {
  setIsModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  medicalActCode: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setFilterCriterias: PropTypes.func.isRequired,
  resetAllFilters: PropTypes.func.isRequired,
  filterCriterias: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    interventions: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  }).isRequired,
};
export default FilterModal;
