import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/material/locale";
import { theme } from "@docaposte-agility/da-design-system";

const CAIHTheme = createTheme(
  theme,
  {
    palette: {
      cobalt: {
        focused: "#3363CC",
      },
    },
    shadows: [
      ...theme.shadows,
      "0px 0px 15px 0px rgba(0, 0, 45, 0.04), 0px 0px 0px 0px rgba(0, 0, 46, 0.06)",
    ],
    components: {
      MuiBox: {
        styleOverrides: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "CAIHLite" },
            style: {
              ...theme.components.MuiButton.styleOverrides.sizeSmall,
              ...theme.components.MuiButton.styleOverrides.outlined,
              height: 32,
              ":hover": {
                backgroundColor: theme.palette.cobalt.bleuDigital10,
                color: theme.palette.cobalt.bleuDigital100,
              },
              ":active": {
                backgroundColor: theme.palette.cobalt.focused,
                color: theme.palette.cobalt.white,
              },
              ":focus": {
                backgroundColor: theme.palette.cobalt.selected,
                color: theme.palette.cobalt.white,
              },
              ":disabled": {
                backgroundColor: theme.palette.cobalt.grey10,
                color: theme.palette.cobalt.grey30,
                borderColor: theme.palette.cobalt.grey10,
              },
            },
          },
          {
            props: { variant: "CAIHConsentButton" },
            style: {
              ...theme.components.MuiButton.styleOverrides.sizeSmall,
              ...theme.components.MuiButton.styleOverrides.outlined,
              height: 32,
              ":hover": {
                backgroundColor: theme.palette.cobalt.bleuDigital10,
                color: theme.palette.cobalt.bleuDigital100,
              },
              ":disabled": {
                borderColor: theme.palette.cobalt.grey10,
                backgroundColor: theme.palette.cobalt.grey10,
                color: theme.palette.cobalt.grey30,
              },
              "&.selected": {
                color: theme.palette.cobalt.white,
                backgroundColor: "#2F8375",
                borderColor: "#2F8375",
              },
              "&.refused": {
                color: theme.palette.cobalt.white,
                backgroundColor: "#D7376C",
                borderColor: "#D7376C",
              },
            },
          },
          {
            props: { variant: "delta" },
            style: {
              textTransform: "none",
              color: theme.palette.cobalt.ultramarine,
              "&:hover": {
                background: theme.palette.cobalt.bleuDigital05,
              },
              "&:focus": {
                background: theme.palette.cobalt.focused,
              },
            },
          },
        ],
      },
      MuiSnackbar: {
        styleOverrides: {
          anchorOriginTopRight: {
            top: `${theme.spacing(2)} ! important`,
            right: `${theme.spacing(2)} ! important`,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            opacity: 1,
            backgroundColor: theme.palette.cobalt.userBlue,
            color: theme.palette.cobalt.white,
            padding: "16px",
            fontSize: 14,
            fontWeight: 400,
            maxWidth: 335,
          },
          arrow: {
            color: theme.palette.cobalt.userBlue,
          },
          touch: {
            opacity: 1,
            backgroundColor: theme.palette.cobalt.userBlue,
            color: theme.palette.cobalt.white,
            padding: "16px",
          },
        },
      },

      MuiIconButton: {
        style: {
          "&.MuiIconButton-root": {
            width: "20px",
            height: "20px",
            position: "absolute",
            color: theme.palette.cobalt.ultramarine,
            [theme.breakpoints.up("md")]: {
              top: -10,
            },
          },
          "& label": {
            "& svg": {
              fontSize: "16px",
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            paddingLeft: "24px",
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            position: "absolute",
            left: -25,
            top: 8,
            backgroundColor: theme.palette.cobalt.white,
            borderRadius: "8px",
            textTransform: "capitalize",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "18px 32px",
            [theme.breakpoints.up("sm")]: {
              padding: "18px 32px",
            },
          },
        },
      },
    },
  },
  frFR,
);

export default CAIHTheme;
