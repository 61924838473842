/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { useKeycloak } from "context/KeycloakProvider";

const ContextRoute = props => {
  const { component: Component, setCodeEtab, ...route } = props;
  const { isAuthenticated, keycloak } = useKeycloak();

  useEffect(() => {
    if (isAuthenticated)
      return keycloak.logout({ id_token_hint: keycloak.idToken });
    return null;
  }, []);

  if (!keycloak.authenticated) {
    return (
      <Route
        exact
        render={routeProps => (
          <Component {...routeProps} setCodeEtab={setCodeEtab} />
        )}
        {...route}
      />
    );
  }
  return null;
};
ContextRoute.propTypes = {
  component: PropTypes.func.isRequired,
  setCodeEtab: PropTypes.func.isRequired,
};

export default ContextRoute;
