import Keycloak from "keycloak-js";

const REALM = window.__ENV__.KEYCLOAK_CONF_REALM;
const CLIENT_ID = window.__ENV__.KEYCLOAK_CONF_CLIENT_ID;
const URL = window.__ENV__.KEYCLOAK_CONF_SERVER;

const keycloak = new Keycloak({
  realm: REALM,
  clientId: CLIENT_ID,
  url: URL,
});

export default keycloak;
