import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import { Paper, Box, Typography, Grid, Divider, Stack } from "@mui/material";

import EmptyStateImg from "../../../images/emptystate.png";

import DetailItem from "./DetailItem";
import ConsentReminder from "./ConsentReminder";

import ConsentDetailItem from "./ConsentDetailItem";
import ConsentService from "../../../services/ConsentService";
import SnackbarUtil from "../../../utils/SnackbarUtil";

const ConsentDetailPanel = props => {
  const {
    selectedIntervention,
    sendEmailRelaunch,
    isRelaunchSent,
    currentHeight,
  } = props;
  const [hasRequestedConsents, setHasRequestedConsents] = useState(false);
  const [hasLegalRepresentative, setHasLegalRepresentative] = useState([]);
  const { getConsentPDFDoc } = ConsentService;
  const setSnackbar = SnackbarUtil.useSnackbar()[1];

  useEffect(() => {
    if (selectedIntervention !== null) {
      const requestedConsents = selectedIntervention.consents.filter(
        consent => consent.status === "REQUESTED",
      ).length;
      const rpList =
        selectedIntervention.consents[0].representativeLegalList ?? [];
      setHasRequestedConsents(requestedConsents > 0);
      setHasLegalRepresentative(rpList.length > 0);
    }
  }, [selectedIntervention]);

  const generateTempLink = (file, actCode) => {
    const link = document.createElement("a");
    link.setAttribute(
      "download",
      `${selectedIntervention.patientLocalIpp}_${selectedIntervention.interventionId}_${actCode}.pdf`,
    );
    link.setAttribute("href", `data:application/octet-stream;base64,${file}`);
    link.setAttribute("rel", "noreferrer");
    link.click();
  };

  const handleSelectedConsentDownload = async (id, actCode) => {
    try {
      const { patientLocalIpp, healthFacilityId } = selectedIntervention;
      const doc = await getConsentPDFDoc(id, patientLocalIpp, healthFacilityId);
      generateTempLink(doc.data, actCode);
      setSnackbar({
        isOpen: true,
        message: `Le consentement du patient ${patientLocalIpp} a bien été téléchargé`,
        type: "success",
        autoHideDuration: 5000,
      });
    } catch (err) {
      setSnackbar({
        isOpen: true,
        message: "Erreur lors du téléchargement du document",
        type: "error",
        autoHideDuration: 5000,
      });
    }
  };

  return (
    <Paper
      sx={{
        minHeight: currentHeight,
        minWidth: "306px",
        position: "sticky",
        top: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent:
          selectedIntervention !== null ? "space-between" : "flex-start",
      }}
    >
      <Box
        p={3}
        sx={{
          height: "100%",
        }}
      >
        <Typography variant="subtitle1">Détail des consentements</Typography>
        {selectedIntervention === null ? (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "100%",
            }}
          >
            <img
              src={EmptyStateImg}
              alt="aucun consentement"
              style={{
                width: "122px",
                height: "auto",
              }}
            />
            <Typography variant="body2" align="center">
              Séléctionnez un patient pour afficher le détail des consentements
              liés à son intervention
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: "24px",
              position: "relative",
              height: "calc(100% - 48px)",
            }}
          >
            <Divider
              sx={{
                marginLeft: "-24px",
                marginRight: "-24px",
                marginBottom: "20px",
              }}
            />
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box mb={2} sx={{ width: "100%" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <DetailItem
                    label="Nom du patient"
                    content={`${selectedIntervention.patientFirstName} ${selectedIntervention.patientLastName}`}
                  />
                  <DetailItem
                    label="Date de naissance"
                    content={format(
                      new Date(selectedIntervention.patientBirthDate),
                      "dd/MM/yyyy",
                    )}
                  />
                </Grid>
              </Box>
              <Box mb={2} sx={{ width: "100%" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <DetailItem
                    label="IPP"
                    content={selectedIntervention.patientLocalIpp}
                  />
                  <DetailItem
                    label="Date de l'intervention"
                    content={format(
                      new Date(selectedIntervention.interventionDate),
                      "dd/MM/yyyy",
                    )}
                  />
                </Grid>
              </Box>
              <Stack
                mb={4}
                sx={{ width: "100%" }}
                divider={<Divider orientation="horizontal" flexItem />}
              >
                {selectedIntervention?.consents.map((consent, index) => (
                  <ConsentDetailItem
                    key={`consent--${Number(index)}`}
                    consent={consent}
                    handleSelectedConsentDownload={
                      handleSelectedConsentDownload
                    }
                  />
                ))}
              </Stack>
            </Grid>
          </Box>
        )}
      </Box>
      {hasRequestedConsents && selectedIntervention !== null && (
        <ConsentReminder
          selectedIntervention={selectedIntervention}
          isRelaunchSent={isRelaunchSent}
          sendEmailRelaunch={sendEmailRelaunch}
          hasLegalRepresentative={hasLegalRepresentative}
        />
      )}
    </Paper>
  );
};

ConsentDetailPanel.defaultProps = {
  selectedIntervention: null,
  isRelaunchSent: null,
  currentHeight: "calc(100vh - 236px)",
};

//! Check PropTypes
ConsentDetailPanel.propTypes = {
  selectedIntervention: PropTypes.shape({
    healthFacilityId: PropTypes.string,
    intentId: PropTypes.string,
    interventionDate: PropTypes.string,
    interventionId: PropTypes.string,
    medicalActCode: PropTypes.string,
    patientBirthDate: PropTypes.string,
    patientFirstName: PropTypes.string,
    patientId: PropTypes.string,
    patientLastName: PropTypes.string,
    patientLocalIpp: PropTypes.string,
    statusHistory: PropTypes.arrayOf(
      PropTypes.shape({ status: PropTypes.string, date: PropTypes.string }),
    ),
    consents: PropTypes.arrayOf(
      PropTypes.shape({
        healthFacilityId: PropTypes.string,
        representativeLegalList: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    ),
  }),
  isRelaunchSent: PropTypes.bool,
  sendEmailRelaunch: PropTypes.func.isRequired,
  currentHeight: PropTypes.number,
};

export default ConsentDetailPanel;
