import React from "react";
import { SvgIcon } from "@mui/material";

const InputSearchIcon = () => (
  <SvgIcon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    style={{ width: "16px" }}
  >
    <path
      d="M12.8471 14.0276C11.5642 15.0536 9.93706 15.667 8.16663 15.667C4.02449 15.667 0.666626 12.3091 0.666626 8.16699C0.666626 4.02486 4.02449 0.666992 8.16663 0.666992C12.3088 0.666992 15.6666 4.02486 15.6666 8.16699C15.6666 9.93825 15.0526 11.5661 14.0258 12.8493L17.0879 15.9114C17.4134 16.2368 17.4134 16.7645 17.0879 17.0899C16.7625 17.4153 16.2348 17.4153 15.9094 17.0899L12.8471 14.0276ZM2.33329 8.16699C2.33329 11.3887 4.94496 14.0003 8.16663 14.0003C9.73626 14.0003 11.1611 13.3804 12.2096 12.372C12.2323 12.3422 12.2572 12.3136 12.2844 12.2864C12.3117 12.2591 12.3404 12.2341 12.3702 12.2114C13.3794 11.1628 14 9.73735 14 8.16699C14 4.94533 11.3883 2.33366 8.16663 2.33366C4.94496 2.33366 2.33329 4.94533 2.33329 8.16699Z"
      fill="#00008C"
    />
  </SvgIcon>
);

export default InputSearchIcon;
