const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => {
  if (order === "desc") {
    return (a, b) => descendingComparator(a, b, orderBy);
  }
  return (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const stableOrder = comparator(a[0], b[0]);

    if (stableOrder !== 0) return stableOrder;
    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
};

export default { getComparator, stableSort };
