const interventions = [
  {
    id: "CHIRREFRAC",
    name: "Chirurgie réfractive",
  },
  {
    id: "IVCORATL",
    name: "Anesthésie",
  },
];

const getInterventionById = id =>
  interventions.filter(intervention => intervention.id === id)[0].name;
const getInterventions = () => interventions;

const getAllConsentsStatus = consents => {
  const patientInterventionStatus = [];
  consents.forEach(consent => {
    if (!patientInterventionStatus.includes(consent.status)) {
      patientInterventionStatus.push(consent.status);
    }
  });

  return patientInterventionStatus;
};

export default { getInterventionById, getInterventions, getAllConsentsStatus };
