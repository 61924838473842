const AccessUtils = {
  isAccessValid: accessArr => {
    if (accessArr.length > 0 && accessArr[0] !== "" && accessArr[0] !== null) {
      return true;
    }
    return false;
  },
  isPageAccessible: (userAccess, access) => {
    if (userAccess.includes(`OPERATOR_${access}`)) {
      return true;
    }
    return false;
  },
};

export default AccessUtils;
