import { createContext } from "react";
import Keycloak from "keycloak-js";

export type KeycloakContextType = {
  keycloak: Keycloak;
  initialized: boolean;
  isAuthenticated: boolean;
};

export const KeycloakContext = createContext<KeycloakContextType | null>(null);
