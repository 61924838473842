/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import CAIHCard from "../CAIHCard";
import TextSkeleton from "../TextSkeleton";

function ConsentContent(props) {
  const { content, isLoading } = props;
  const [showMore, setShowMore] = useState(false);
  const contentIsUnique = content.length === 1;
  const firstContentSize = content.length < 1 ? 0 : content[0].length;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const showMoreBtn = {
    userSelect: "none",
    textAlign: "center",
    borderColor: "cobalt.grey10",
    padding: "10px 10px 10px 0px",
    "&:hover": {
      cursor: "pointer",
    },
  };
  const showMoreBtnMobile = {
    marginTop: "16px",
    paddingTop: "8px",
    borderTopStyle: "solid",
    borderTopWidth: "1px",
  };

  const getShowMoreBtnStyle = () => {
    if (isMobile) {
      return { ...showMoreBtn, ...showMoreBtnMobile };
    }
    return showMoreBtn;
  };

  const getDisplayedContents = () => {
    if (!showMore) {
      const firstContentArray = [];
      const firstElem =
        firstContentSize > 399
          ? `${content[0].substring(0, 399)}...`
          : content[0];
      firstContentArray.push(firstElem);

      return firstContentArray;
    }

    return content;
  };

  return (
    <Box>
      <CAIHCard hasPadding={false} cardContentNoPadding>
        {isLoading ? (
          <Box p={4}>
            <TextSkeleton lines={5} />
          </Box>
        ) : (
          <Box p={4}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    position: "relative",
                    "& ul": {
                      listStyle: "none",
                      padding: 0,
                    },
                    "& li": {
                      position: "relative",
                      fontFamily: "'Barlow', sans-serif",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      color: "cobalt.ultramarine",
                      marginBottom: "16px",
                    },
                    "& li::before": {
                      position: "absolute",
                      top: "4px",
                      left: "-28px",
                      borderRadius: "8px",
                      verticalAlign: "middle",
                      display: "inline-block",
                      width: "8px",
                      height: "8px",
                      background: "cobalt.lynch",
                      content: "''",
                    },
                  }}
                >
                  {contentIsUnique ? (
                    <Box
                      sx={{
                        fontFamily: "'Barlow', sans-serif",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        color: "cobalt.ultramarine",
                      }}
                    >
                      {firstContentSize > 399
                        ? showMore
                          ? content[0]
                          : `${content[0].substring(0, 399)}...`
                        : content[0]}
                    </Box>
                  ) : (
                    <ul>
                      {getDisplayedContents().map((contentLabel, i) => (
                        <li key={`label-${i.toString()}`}>{contentLabel}</li>
                      ))}
                    </ul>
                  )}
                  {contentIsUnique && firstContentSize > 399 && (
                    <Grid item xs={12}>
                      <Box
                        sx={{ ...getShowMoreBtnStyle() }}
                        onClick={() => setShowMore(!showMore)}
                      >
                        <Typography
                          variant="h5"
                          sx={{ color: "cobalt.bleuDigital100" }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            {showMore ? "Voir moins" : "Voir plus"}

                            {showMore ? (
                              <CobaltIcon
                                name="chevron-up"
                                style={{ fontSize: "20px", margin: "4px" }}
                              />
                            ) : (
                              <CobaltIcon
                                name="chevron-down"
                                style={{ fontSize: "20px", margin: "4px" }}
                              />
                            )}
                          </Grid>
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {!contentIsUnique && (
                    <Grid item xs={12}>
                      <Box
                        sx={{ ...getShowMoreBtnStyle() }}
                        onClick={() => setShowMore(!showMore)}
                      >
                        <Typography
                          variant="h5"
                          sx={{ color: "cobalt.bleuDigital100 " }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            {showMore ? "Voir moins" : "Voir plus"}

                            {showMore ? (
                              <CobaltIcon name="chevron-up" />
                            ) : (
                              <CobaltIcon name="chevron-down" />
                            )}
                          </Grid>
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </CAIHCard>
    </Box>
  );
}

ConsentContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ConsentContent;
